import { Injectable, OnDestroy } from '@angular/core';

const ASSETS:{ [key:string]: string } = {
    'spark': "data:image/svg+xml;utf-8," + encodeURIComponent(`<svg width="391" height="391" viewBox="0 0 391 391" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M195.5 0L218.425 87.6475L275.017 16.9019L260.31 106.296L340.785 64.685L290.99 140.369L381.432 135.087L305.158 183.974L389.929 215.935L300.365 229.573L364.808 293.25L277.441 269.28L310.412 353.663L240.348 296.229L236.147 386.728L195.5 305.762L154.853 386.728L150.652 296.229L80.588 353.663L113.559 269.28L26.192 293.25L90.6346 229.573L1.07097 215.935L85.842 183.974L9.56845 135.087L100.01 140.369L50.2152 64.685L130.69 106.296L115.983 16.9019L172.575 87.6475L195.5 0Z" fill="#FF7A00"/>
</svg>`),
    'useitia':"data:image/svg+xml;utf-8,"+ encodeURIComponent(`<svg width="51" height="12" viewBox="0 0 51 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.5454 0.00012207H35V11.364H34.5454V0.00012207Z" fill="url(#paint0_linear_2193_9492)"/>
<path d="M3.35198 11.5643C2.78215 11.5643 2.24024 11.4303 1.72627 11.1621C1.2123 10.8828 0.793303 10.4749 0.469278 9.93863C0.156426 9.39114 0 8.72074 0 7.92744V3.05031H1.25699V7.89392C1.25699 8.65371 1.47487 9.26824 1.91063 9.73751C2.35756 10.1956 2.92181 10.4247 3.60338 10.4247C4.07266 10.4247 4.49724 10.3185 4.87714 10.1062C5.25703 9.88276 5.55871 9.58109 5.78217 9.20119C6.01681 8.8213 6.13413 8.37996 6.13413 7.87716V3.05031H7.39112V11.4303H6.13413V10.123C5.83245 10.5252 5.46373 10.866 5.02798 11.1453C4.59222 11.4247 4.03355 11.5643 3.35198 11.5643Z" fill="black"/>
<path d="M12.1242 11.5643C11.3533 11.5643 10.6996 11.4359 10.1633 11.1789C9.63817 10.9219 9.23594 10.5867 8.9566 10.1733C8.67727 9.74869 8.52085 9.29058 8.48733 8.79896H9.7946C9.82812 9.07829 9.92309 9.35203 10.0795 9.62019C10.2471 9.87718 10.4985 10.0895 10.8337 10.2571C11.1689 10.4135 11.6047 10.4917 12.141 10.4917C12.3086 10.4917 12.5153 10.4749 12.7611 10.4414C13.0069 10.4079 13.2416 10.3465 13.465 10.2571C13.6997 10.1677 13.8952 10.0336 14.0516 9.85483C14.208 9.67606 14.2863 9.44701 14.2863 9.16768C14.2863 8.8213 14.1522 8.55314 13.884 8.3632C13.6159 8.17325 13.2695 8.028 12.8449 7.92744C12.4203 7.81571 11.9678 7.70956 11.4874 7.609C11.0181 7.50844 10.5711 7.37995 10.1466 7.22352C9.72197 7.05593 9.3756 6.82687 9.10744 6.53637C8.83928 6.23469 8.7052 5.82687 8.7052 5.31289C8.7052 4.55311 8.97895 3.96651 9.52644 3.5531C10.0851 3.12852 10.9063 2.91623 11.9901 2.91623C12.7276 2.91623 13.3254 3.03355 13.7835 3.26818C14.2527 3.49165 14.6047 3.78216 14.8393 4.1397C15.0851 4.49724 15.2304 4.88272 15.2751 5.29613H14.0013C13.9566 4.93859 13.7779 4.63132 13.465 4.37434C13.1633 4.11735 12.6605 3.98886 11.9566 3.98886C10.627 3.98886 9.9622 4.3911 9.9622 5.19558C9.9622 5.53077 10.0963 5.78776 10.3644 5.96653C10.6326 6.1453 10.979 6.29056 11.4036 6.40229C11.8281 6.50285 12.2751 6.60341 12.7443 6.70397C13.2248 6.79335 13.6773 6.92185 14.1019 7.08945C14.5265 7.25704 14.8729 7.49727 15.141 7.81012C15.4092 8.1118 15.5433 8.52521 15.5433 9.05036C15.5433 9.86601 15.2304 10.4917 14.6047 10.9275C13.9902 11.3521 13.1633 11.5643 12.1242 11.5643Z" fill="black"/>
<path d="M20.3772 11.5643C19.528 11.5643 18.785 11.3744 18.1481 10.9945C17.5112 10.6034 17.014 10.0783 16.6565 9.41907C16.3101 8.75985 16.1369 8.03359 16.1369 7.24029C16.1369 6.43581 16.3045 5.70955 16.6397 5.0615C16.9749 4.40227 17.4554 3.88271 18.0811 3.50282C18.7068 3.11176 19.4442 2.91623 20.2934 2.91623C21.1537 2.91623 21.8912 3.11176 22.5057 3.50282C23.1314 3.88271 23.6118 4.40227 23.947 5.0615C24.2822 5.70955 24.4498 6.43581 24.4498 7.24029V7.74308H17.4274C17.4945 8.23471 17.6509 8.68722 17.8967 9.10064C18.1537 9.50287 18.4945 9.8269 18.9191 10.0727C19.3436 10.3074 19.8353 10.4247 20.3939 10.4247C20.9861 10.4247 21.4833 10.2962 21.8856 10.0392C22.2878 9.77103 22.6007 9.43025 22.8241 9.01684H24.1984C23.9079 9.76545 23.4442 10.38 22.8074 10.8604C22.1817 11.3297 21.3716 11.5643 20.3772 11.5643ZM17.4442 6.56989H23.1426C23.0308 5.8548 22.7292 5.25703 22.2375 4.77658C21.7459 4.29613 21.0979 4.0559 20.2934 4.0559C19.4889 4.0559 18.8409 4.29613 18.3492 4.77658C17.8688 5.25703 17.5671 5.8548 17.4442 6.56989Z" fill="black"/>
<path d="M25.4326 1.64247V0H26.7902V1.64247H25.4326ZM25.4829 11.4303V3.05031H26.7399V11.4303H25.4829Z" fill="black"/>
<path d="M29.3223 11.4303V4.15646H27.6127V3.05031H29.3223V0.0502801H30.5793V3.05031H32.5066V4.15646H30.5793V11.4303H29.3223Z" fill="black"/>
<path d="M38.2212 11.4633V0.690939H39.4416V11.4633H38.2212Z" fill="#FF7A00"/>
<path d="M40.3253 11.4633L44.8978 0.690939H46.4275L51 11.4633H49.6494L48.3313 8.33897H42.994L41.6759 11.4633H40.3253ZM43.4659 7.21618H47.8594L45.6626 1.96019L43.4659 7.21618Z" fill="#FF7A00"/>
<defs>
<linearGradient id="paint0_linear_2193_9492" x1="34.7727" y1="0.00012207" x2="34.7727" y2="11.364" gradientUnits="userSpaceOnUse">
<stop stop-color="#DFDFDF" stop-opacity="0"/>
<stop offset="0.25" stop-color="#DFDFDF"/>
<stop offset="0.5" stop-color="#DFDFDF"/>
<stop offset="0.75" stop-color="#DFDFDF"/>
<stop offset="1" stop-color="#DFDFDF" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>
`)
}

@Injectable({
  providedIn: 'root'
})
export class EmbeddedAssetsService implements OnDestroy {

    private assets: { [key:string]: string } = {};
    constructor() {
        this.loadAssets().then(()=>{
            console.log(`DEBUG ${this.constructor.name} assets loaded`);
        }).catch(err=>{
            console.log(`DEBUG ${this.constructor.name} asset loading failed`, err);
        })
    }

    private async * assetGenerator() {
        const keys = Object.keys(ASSETS);
        for (let key of keys ){
            const dataUrl = ASSETS[key];
            const resp = await fetch(dataUrl);
            const blob = await resp.blob();
            const retv = { key: key, url: URL.createObjectURL(blob) };
            yield retv;
        }
    }

    private async loadAssets() {
        for await (const {key, url} of this.assetGenerator()) {
            this.assets[key] = url;
        }
    }

    ngOnDestroy(): void {
        Object.keys(this.assets).forEach(key=>{
            URL.revokeObjectURL(this.assets[key])
        });
    }

    get(key:string) {
        return this.assets[key];
    }
}