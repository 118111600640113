import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { environment } from 'src/environments/environment';

import { BehaviorSubject, Observable, tap } from 'rxjs';

@Component({
  selector: 'app-api-keys',
  standalone: true,
  imports: [ CommonModule, MatToolbarModule, MatButtonModule, MatIconModule, MatListModule, MatFormFieldModule, MatInputModule ],
  templateUrl: './api-keys.component.html',
  styleUrl: './api-keys.component.scss'
})
export class ApiKeysComponent implements OnInit {

  @Input('uuid') projectUUID:string;
  @Input('espai') espaiUUID: string;

  private _tokens = new BehaviorSubject<any[]>([]);
  tokens$ = this._tokens.asObservable();
  
  constructor(
    private api: ApiService,
  ) {}

  ngOnInit(): void {
    this.api.getApiTokens(this.projectUUID, this.espaiUUID).pipe(
      this.api.uify()
    ).subscribe(this._tokens);
  }

  addToken(domain:string) {
    this.api.createApiToken(this.projectUUID, this.espaiUUID, domain).subscribe({
      next: (token)=>{
        const ct = this._tokens.value;
        ct.push({ domain, token});
        this._tokens.next(ct);
      }
    })
  }
  
  debug(sth:any, tag="") {
    if (environment.production) return;
    console.log(`DEBUG ${this.constructor.name} ${tag}`, sth);
  }

}
