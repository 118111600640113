
<form [formGroup]="editForm">
    <mat-form-field appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput  formControlName="name" [value]="projecte.name">
    </mat-form-field>
    <mat-checkbox formControlName="enabled" [checked]="projecte.enabled">Enabled</mat-checkbox>
</form>
<button mat-button color="primary" (click)="editProj()" [disabled]="editForm.pristine || editForm.invalid">
    <mat-icon>edit</mat-icon>
    Editar
</button>
<button mat-button color="primary" (click)="deleteProj()">
    <mat-icon>delete</mat-icon>
    Eliminar
</button>