import { Component, model, effect, TemplateRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ApiService, ITopic } from 'src/app/api.service';

import { Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

interface IBareTopic extends Partial<ITopic> {
  key:string;
  name:string;
  uuid:string;
}

@Component({
  selector: 'app-topic-editor',
  templateUrl: './topic-editor.component.html',
  styleUrl: './topic-editor.component.scss'
})
export class TopicEditorComponent implements OnDestroy {
  
  topics = model<IBareTopic[]>();
  private _topics$ = new Subject<IBareTopic[]>(); 
  public topics$ = this._topics$.asObservable()
  // .pipe(
  //   tap(nxt=>this.debug(nxt, 'topics refreshed:'))
  // );

  constructor(
    private aroute: ActivatedRoute,
    private api: ApiService,
    private dialog: MatDialog,
  ) {
    effect(()=>{
      const currentTopics = this.topics();
      this._topics$.next(currentTopics || []);
    })
  }

  ngOnDestroy(): void {
      this._topics$.complete();
  }

  createTopic(template:TemplateRef<any>, edit?:IBareTopic) {
    const dref = this.dialog.open<any,IBareTopic|undefined,IBareTopic|undefined>(template, {
      data: edit
    });
    dref.afterClosed().subscribe({
      next: (payload)=>{
        if (!payload) return;
        const { uuid, espai } = this.aroute.snapshot.params;
        (edit?
          this.api.editTopic(uuid, espai, payload):
          this.api.createTopic(uuid, espai, payload)
        ).pipe(
          this.api.uify('Enviando...')
        ).subscribe({
          next:(resp)=>{
            console.log(`DEBUG ${this.constructor.name} createTopic/editTopic responds:`, resp);
            const current = this.topics() || [];
            if (edit) {
              if (payload.deleted) {
                this.topics.set(current.filter(t=>t.key!=edit.key))
              } else {
                const elem = current.find(t=>t.key==edit.key);
                elem!.name = resp.name;
                this.topics.set([...current])
              }
            } else {
              this.topics.set([...current, resp])
            }
          }
        });
      }
    })
  }

  deleteTopic(topic:IBareTopic) {
    const payload = { ...topic };
    payload.deleted = true;
    const { uuid, espai } = this.aroute.snapshot.params;

    const confirmRequest = this.api.editTopic(uuid, espai, payload).pipe(this.api.uify('Enviando...'));

    this.api.confirmDialog(confirmRequest, `¿Quiere eliminar el tema "${topic?.name}"?`).subscribe({
      next:(resp)=>{
        const current = this.topics() || [];
        this.topics.set(current.filter(t=>t.key!=topic.key))
      }
    });
  }

  debug(sth:any, tag:string='') {
    if (environment.production) return;
    console.log(`DEBUG ${this.constructor.name} ${tag}`, sth)
  }

}
