import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../api.service';
import * as moment from 'moment';

export enum MessageSenders {
  AGENT = 'AGENT',
  USER = 'USER',
  LLM = 'LLM',
};

export type ConversationType = {
  message: string;
  sender: MessageSenders;
  timestamp?: string;
}

export enum ConversationState {
  ACTIVE = 'ACTIVE',
  ENDED = 'ENDED',
  NEED_HUMAN_HELP = 'NEED_HUMAN_HELP',
};

@Component({
  selector: 'app-agent-chat',
  standalone: false,
  templateUrl: './agent-chat.component.html',
  styleUrl: './agent-chat.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AgentChatComponent {

  @Input('sessionUuid') sessionUuid: string;
  @Input('uuid') uuid: string;
  @Input('espai') espai: string;
  @Input('view') view: MessageSenders;

  @ViewChild('chat') chat: ElementRef;

  conversation: ConversationType[];

  inputMessage: string;
  loadingMessage: boolean = false;
  intervalRef: any;
  lastMessageTimestamp: string = moment().format();

  constructor(private api: ApiService) { 

  }

  ngOnInit() {
    this.fetchConversation();
  }

  ngAfterViewInit() {
    this.scrollChatDown();
    this.lastMessageTimestamp = moment().format();
    this.intervalRef = setInterval(() => {
      this.fetchConversationNoLoader();
    }, 5000);
  }

  ngOnDestroy() {
    if(this.intervalRef) {
      clearInterval(this.intervalRef);
    }
  }

  scrollChatDown() {
    setTimeout(() => {
      this.chat.nativeElement.scrollTo({
        top: this.chat.nativeElement.scrollHeight,
        behavior: 'smooth'
      });
    }, 1000);
  }

  private fetchConversation() {
    this.api.getAllConversation(this.uuid, this.espai, this.sessionUuid, true).subscribe({
      next: (nxt) => {
        this.conversation = nxt.conversations;
        this.scrollChatDown();
      },
    });
  }

  private fetchConversationNoLoader() {
    this.api.getAllConversationNoLoader(this.uuid, this.espai, this.sessionUuid, true).subscribe({
      next: (nxt) => {
        this.conversation = nxt.conversations;
        console.log('fetching conversation', this.conversation);
        this.scrollChatDown();
        if(this.conversation.length == 0) return;
        const now = moment();
        if(now.diff(moment(this.lastMessageTimestamp), 'minutes') > 10) this.endSession();
        this.lastMessageTimestamp = moment(this.conversation[this.conversation.length - 1].timestamp!!).format();
      },
    });
  }

  endSession() {
    this.api.setSessionEnded(this.uuid, this.espai, this.sessionUuid).subscribe({
      next: (nxt) => {
        clearInterval(this.intervalRef);
      },
      error: (err) => {
        clearInterval(this.intervalRef);
      },
    });
  }

  isMessageUserView(message: ConversationType) {
    if(this.view == MessageSenders.USER) {
      return message.sender === this.view;
    }
    else {
      if (message.sender === MessageSenders.LLM || message.sender == MessageSenders.AGENT) { return true; }
      else return false;
    }
  }
  
  sendMessage() {
    if (this.inputMessage == '' || this.inputMessage == undefined || this.inputMessage == null) return;
    this.loadingMessage = true;
    this.scrollChatDown();
    this.api.postConversationMessage(this.uuid, this.espai, this.sessionUuid, this.inputMessage, this.view).subscribe({
      next: (nxt) => {
        this.loadingMessage = false;
        this.conversation.push({ message: this.inputMessage, sender: this.view });
        this.scrollChatDown();
        this.inputMessage = '';
      },
      error: (err) => {
        this.loadingMessage = false;
        this.conversation.push({ message: "Alguna cosa ha anat malament, el missatge no s'ha enviat", sender: this.view});
        this.scrollChatDown();
        this.inputMessage = '';
      }
    });
  }

}
