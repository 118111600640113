import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISpace, ApiService, ISearchConfiguration } from 'src/app/api.service';
import { getLanguageNameWithLocale } from 'src/app/domain/IndexingLanguages';

@Component({
  selector: 'app-space-detail',
  templateUrl: './space-detail.component.html',
  styleUrl: './space-detail.component.scss'
})
export class SpaceDetailComponent implements OnInit, OnDestroy {

  @Input() uuid: string;
  @Input() espai: string;
  @Input() detail?:ISpace
  @Input() userRole?:string;

  expandCard: boolean = false;

  private _searchConfigs = new BehaviorSubject<ISearchConfiguration[]>([]);
  public searchConfigs$ = this._searchConfigs.asObservable();

  constructor(private api: ApiService) {

  }

  ngOnInit() {
    this.api.searchConfigurations(this.uuid, this.espai).subscribe(this._searchConfigs);
  }

  ngOnDestroy(): void {
      this._searchConfigs.complete();
  }

  getLanguageName(locale: string) {
    return getLanguageNameWithLocale(locale);
  }

  userHasAdminRights() {
    return this.api.userHasAdminRights();
  }

}
