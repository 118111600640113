import { 
  Component, Input, Output, EventEmitter, model, effect,
  WritableSignal, signal, computed, TemplateRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { ActivatedRoute } from '@angular/router'; // <- obté l'uuid del projecte i l'espai de la ruta

import { MatDialog } from '@angular/material/dialog';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { environment } from 'src/environments/environment';

import { ApiService, ICategoryNode, ISearchConfiguration, ISearchConfigurationItem } from '../api.service';

export interface ICategoryTreeOptions {
  searchConfiguration?: boolean,
  categoryCreation?: boolean,
  categorySelection?: boolean // <- activat si s'habilita el searchConfiguration (s'ignora el valor passat)
}


interface ICategorySelection extends ICategoryNode {
  completed?: boolean,
}

class CategorySignals {
  readonly parent?:CategorySignals;
  readonly catSignal:WritableSignal<ICategorySelection>;
  public children:CategorySignals[] = [];
  public idx?:number

  constructor(node:ICategorySelection, parent?: CategorySignals, childIdx?:number) {
    this.parent = parent;
    this.idx = childIdx;
    this.catSignal = signal<ICategorySelection>(node);
    node.children?.forEach((child, n)=>{
      this.children.push(new CategorySignals(child, this, n));
    })
  }

  readonly partiallyComplete = computed(() => {
    if (!this.children) {
      return false;
    }
    // cal també marcar-ho parcial si algun dels fills és parcial!
    const partialOffspring = this.children.some((c)=>c.partiallyComplete());
    return partialOffspring || this.children.some((c)=>c.catSignal().completed) && !this.children.every((c)=>c.catSignal().completed);
  });

  update(completed: boolean, index?:number) {
    this.catSignal.update((cat:ICategorySelection)=>{
      cat.completed = completed;
      this.children.forEach((child, n)=>child.update(completed, n));
      if (index != undefined) {
        const updateAncestors = (pnt:CategorySignals)=>{
          const pstatus = pnt.children.map(cs=>cs.catSignal());
          pnt.catSignal.update((val)=>{
            val.completed = pstatus.every(c=>c.completed);
            if (pnt.parent) updateAncestors(pnt.parent)
            return val;
          });
        }
        // cal comprovar si tots els siblings són complets, i si cal, actualitzar el pare!
        updateAncestors(this.parent!);
      }
      return {...cat};
    });
  }
}

@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrl: './category-tree.component.scss'
})
export class CategoryTreeComponent {

  categories = model<ICategoryNode[]>([]);
  @Input() configs: ISearchConfiguration[] = [];
  @Input() options: ICategoryTreeOptions = { searchConfiguration:true, categoryCreation:true, categorySelection: true }
  @Input() selection?:string; // noms de categories separats per comes, p.ex: 'aparcament,circulacio,transit'
  @Output() change = new EventEmitter<{detail:string[]}>();

  signals: { [uuid:string]: CategorySignals } = {}; // <- controladors mapejats per l'uuid de l'ICategoryNode
  currentValue: string[] = [];

  dataSource: MatTreeNestedDataSource<ICategoryNode>;
  treeControl = new NestedTreeControl<ICategoryNode, number>(node=>node.children, {
    trackBy: (node: ICategoryNode) => node.id,
  });

  selectConfig = new FormControl();
  reset: boolean = false;

  constructor(
    private aroute: ActivatedRoute,
    private dialog: MatDialog,
    private api: ApiService,
  ) {
    this.dataSource = new MatTreeNestedDataSource<ICategoryNode>();
    effect(()=>{
      // 1- obtenir el llistat de categories (arbre).
      // 2- recòrrer els nodes arrel per si cal crear un nou arbre
      // 3- recòrrer recursivament els subnodes per si cal crear un nou arbre
      // 4- Quan es crei un nou arbre, cal afegir tots els nodes nous a this.signals 
      const updateSubTree = (elem: ICategoryNode, parentUUID?:string, n?:number)=>{
        if (!this.signals[elem.uuid]) {
          const cpar = parentUUID?this.signals[parentUUID]:undefined;
          const csig = new CategorySignals(elem, cpar, n);
          const classify = (node:CategorySignals)=>{
            const cat = node.catSignal();
            this.signals[cat.uuid] = node;
            node.children.forEach(child=>classify(child))
          }
          classify(csig);
        } else {
          elem.children?.forEach((child, idx)=>updateSubTree(child, elem.uuid, idx))
        }
      }
      const currentCats = this.categories();
      if (!currentCats) return;
      
      currentCats.forEach(cat=>updateSubTree(cat));
      this.dataSource.data = currentCats;
      
      // mostra l'arbre totalment expandit
      currentCats.forEach(c=>this.treeControl.expandDescendants(c));

      // força el refresc de l'arbre al template
      this.reset = true;
      setTimeout(()=>this.reset=false, 1);

    });
  }


  // serveix per triar el template a mostrar segons el tipus de node
  hasChild = (_:number, node: ICategoryNode)=> Array.isArray(node.children) && !!node.children.length;
  
  // Emet els noms de les categories (només nodes fulla), eliminant repetits si cal
  // (l'alternativa seria fer servir els uuid).
  emitValue() {
    this.currentValue = Object.keys(this.signals).filter(
      uuid=>{
        const ctl = this.signals[uuid];
        return !ctl.children.length && ctl.catSignal().completed
      }
    ).map(
      uuid=>this.signals[uuid].catSignal().value as string
    ).sort().filter(
      // elimina repeticions
      (name, idx, ary)=>{
        if (!idx) return true;
        else return ary[idx-1]!=name;
      }
    );
    this.change.emit({ detail: this.currentValue });
  }

  configSelected({ value }: { value: Array<ISearchConfigurationItem> }) {
    // Primer deseleccionem tot (a partir dels root nodes de les categories)
    this.categories().forEach(c=>this.signals[c.uuid].update(false));

    // apliquem les categories del search configuration
    const v_ids = value.map(sci=>`${sci.value}`);
    Object.keys(this.signals).map(
      uuid=>[uuid, this.signals[uuid].catSignal().id]
    ).filter(
      ([uuid, id])=>v_ids.includes(`${id}`)
    ).forEach(([uuid, _])=>{
      const ctl = this.signals[uuid];
      if (ctl.parent) ctl.update(true, 0);
      else ctl.update(true)
    });
  }

  saveConfiguration(template:TemplateRef<any>) {
    console.log(`TODO: ${this.constructor.name} saveConfiguration`, this.currentValue);
    const dref = this.dialog.open<any,null,string>(template);
    dref.afterClosed().subscribe({
      next: (sname)=>{
        if (!sname) return;
        const { uuid, espai } = this.aroute.snapshot.params;
        const payload = {
          name: sname,
          items: this.currentValue.map(uuid=>{ return { key: 'category', value: uuid }; })
        }
        this.api.createSearchConfig(uuid, espai, payload).subscribe({
          next:(resp)=>{
            // cal afegir els ISearchConfigurationItems
            const confItems = this.currentValue.map(uuid=>{
              return { key: 'category', value: this.signals[uuid].catSignal().id } as ISearchConfigurationItem
            });
            this.configs.push({...resp, items:confItems} as ISearchConfiguration);
            this.configs = this.configs.filter(_=>true);
            this.selectConfig.setValue(confItems);
          }
        });
      }
    })
  }

  createCategory(template:TemplateRef<any>, parent?:ICategoryNode) {
    const dref = this.dialog.open<any,null,string>(template);
    dref.afterClosed().subscribe({
      next: (name)=>{
        if (!name) return;
        // obté l'uuid del projecte i l'espai de la ruta
        const { uuid, espai } = this.aroute.snapshot.params;
        this.api.createCategory(uuid, espai, name, parent?.uuid).pipe(
          this.api.uify("Creando categoría", "Un momento....")
        ).subscribe({
          next: ({ categories })=>{
            const [ cat ] = categories;
            if (!parent) this.categories.set([...this.categories(), cat]);
            else {
              const pc = this.signals[parent.uuid].catSignal();
              if (Array.isArray(pc.children)) pc.children.push(cat);
              else pc.children = [ cat ];
              this.categories.set([...this.categories()]);
            }
          }
        })
      }
    });
  }

  editCategory(template:TemplateRef<any>, payload:ICategoryNode) {
    this.debug(payload, 'editCategory original:');
    const dref = this.dialog.open<any,ICategoryNode,Partial<ICategoryNode>>(template, { data: {...payload} });
    dref.afterClosed().subscribe({
      next: (node)=>{
        if (!node) return;
        // obté l'uuid del projecte i l'espai de la ruta
        const { uuid, espai } = this.aroute.snapshot.params;
        this.api.editCategory(uuid, espai, node).pipe(
          this.api.uify("Enviando cambios", "Un momento....")
        ).subscribe({
          next: ({ category })=>{
            this.debug(category, 'editCategory response:')
            const target = this.signals[payload.uuid].catSignal();
            target.value = category.value;
            target.enabled = category.enabled;
            this.categories.set([...this.categories()]);
          }
        });
      }
    });
  }

  deleteCategory(cat:ICategoryNode) {
    // obté l'uuid del projecte i l'espai de la ruta
    const { uuid, espai } = this.aroute.snapshot.params;
    const payload = { ...cat };
    payload.deleted = true;

    this.api.confirmDialog(
      this.api.editCategory(uuid, espai, payload).pipe(this.api.uify("Enviando cambios...."))
      ,`¿Quiere eliminar la categoría "${cat.value}"?`
    ).subscribe({
      next:(nxt)=>this.debug(nxt, 'deleteCategory returns:')
    });
  }

  debug(sth:any, tag='') {
    if (environment.production) return;
    console.log(`DEBUG ${this.constructor.name} ${tag}`, sth);
  }

}
