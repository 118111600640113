import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject } from 'rxjs';

import { IUserQuery } from 'src/app/api.service';


/**
 * Data source for the Sessions view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class SessionDetailDataSource extends DataSource<IUserQuery> {
  private _data$ = new BehaviorSubject<IUserQuery[]>([]);
  
  get data$() {
    return this._data$.asObservable();
  }
  
  get data(): IUserQuery[] {
    return this._data$.getValue()
  }
  
  set data(d:IUserQuery[]) {
    this._data$.next(d);
    if (!!this.paginator) {
      const opts = [5, 10, 15, 20];
      if (d.length > opts[opts.length-1]) opts.push(d.length)
      this.paginator.pageSizeOptions = opts;
    }
  }
  
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;

  constructor() {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<IUserQuery[]> {
    if (this.paginator && this.sort) {
      // Combine everything that affects the rendered data into one update
      // stream for the data-table to consume.
      return merge(this.data$, this.paginator.page, this.sort.sortChange)
        .pipe(map(() => {
          return this.getPagedData(this.getSortedData([...this._data$.value ]));
        }));
    } else if (this.sort) {
      // Si no tenim paginator...
      return merge(this.data$, this.sort.sortChange)
        .pipe(map(() => {
          return this.getSortedData([...this._data$.value ]);
        }));
    }
    else {
      throw Error('Please set the paginator and sort on the data source before connecting.');
    }
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: IUserQuery[]): IUserQuery[] {
    if (this.paginator) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      return data.splice(startIndex, this.paginator.pageSize);
    } else {
      return data;
    }
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: IUserQuery[]): IUserQuery[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort?.direction === 'asc';
      switch (this.sort?.active) {
        case 'query': return compare(a.query, b.query, isAsc);
        case 'data': return compare(new Date(a.since_date).getTime(), new Date(b.since_date).getTime(), isAsc);
        case 'feedback': return compare(a.feedback, b.feedback, isAsc)
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
