<mat-list style="background-color: var(--lighter-bgcolor);color:var(--text-color); display:flex; padding:24px">
    <mat-list-item>
      <span matListItemTitle>HOTTEST TOPIC</span>
      <span matListItemLine>{{hottestTopic}}</span>
    </mat-list-item>
    <mat-list-item>
      <span matListItemTitle>COLDEST TOPIC</span>
      <span matListItemLine>{{coldestTopic}}</span>
    </mat-list-item>
    <mat-list-item>
        <button mat-button color="primary" (click)="loadTopics()">Load Topics</button>
    </mat-list-item>
</mat-list>

<mat-table class="full-width-table" matSort aria-label="Refinery" style="background-color:  var(--background-color); padding:24px"
    #topicTable="matTable" #tSort="matSort">
        
    <!-- search column -->
    <ng-container matColumnDef="search">
        <mat-header-cell *matHeaderCellDef style="flex-basis:40%; border-bottom:none; padding: 1em 0;padding-left:0">
        <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 2 0;text-transform: none;--mat-mdc-form-field-label-offset-x: 2em;">
            <mat-label>Search topic</mat-label>
            <mat-icon matTextPrefix style="position:relative;top:+.33em;">search</mat-icon>
            <input matInput placeholder="..." [formControl]="searchControl" type="search"><!--TODO: observable search-->
        </mat-form-field>
        </mat-header-cell>
    </ng-container>

    <!-- session filter -->
    <ng-container matColumnDef="session-filter">
        <mat-header-cell *matHeaderCellDef style="flex-basis:25%; border-bottom:none; padding: 1em 0">
          <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 0 0;text-transform: none;">
            <mat-label>Session</mat-label>
            <mat-select [formControl]="sessionControl" multiple>
                @for (session of sessions; track session) {
                    <mat-option [value]="session">{{session}}</mat-option>
                }
            </mat-select>
          </mat-form-field>
        </mat-header-cell>
    </ng-container>

    <!-- paginator -->
    <ng-container matColumnDef="paginator">
        <mat-header-cell *matHeaderCellDef style="flex-basis:35%; border-bottom:none; padding: 1em 0">
        <mat-paginator #tPaginator="matPaginator" style="background-color: transparent;"
            [length]="htDataSource.data.length"
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 15, 20]"
            aria-label="Select page">
        </mat-paginator>
        </mat-header-cell>
    </ng-container>

    <!-- topics Column -->
    <ng-container matColumnDef="topics">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Topics</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.topic}}</mat-cell>
        <mat-footer-cell *matFooterCellDef>Topics</mat-footer-cell>
    </ng-container>

    <!-- count Column -->
    <ng-container matColumnDef="count">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Count</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.count}}</mat-cell>
        <mat-footer-cell *matFooterCellDef>Count</mat-footer-cell>
    </ng-container>

    <!-- Sessions Column -->
    <ng-container matColumnDef="sessions">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Sessions</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.sessions.length}}</mat-cell>
        <mat-footer-cell *matFooterCellDef>Sessions</mat-footer-cell>
    </ng-container>
    

    <mat-header-row *matHeaderRowDef="['search', 'session-filter', 'paginator'].concat(displayedColumns); sticky: true;" 
        style="text-transform: uppercase;background-color: var(--background-color);flex-wrap:wrap;">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
        <!-- (click)="row.selected=!row.selected;debug(row, 'row clicked')" matRipple--> <!--[routerLink]="[row.uuid]" [state]="{ source: row }"-->
    </mat-row>
    <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true;"
        style="text-transform: uppercase;background-color: var(--background-color);">
    </mat-footer-row>
</mat-table>
