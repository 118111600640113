
<mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{detail?.name}}</mat-card-title>
      <mat-card-subtitle>{{detail?.uuid}}</mat-card-subtitle>
    </mat-card-header>
    @if (expandCard) {
        <mat-card-content>
            <p>Idioma indexación: {{getLanguageName(detail?.indexing_language!!)}}</p>
            @if (userHasAdminRights()) {
                <p>Uuid proyecto: {{uuid}}</p>
            }
            <p>Fecha de creación: {{detail?.created_at}}</p>
            <p>Fecha de actualización: {{detail?.updated_at}}</p>
        </mat-card-content>
    }
    @if (!expandCard) {
        <mat-card-actions>
            <button mat-button (click)="expandCard = !expandCard">Muestra mas...</button>
          </mat-card-actions>
    }
    @else {
        <mat-card-actions>
            <button mat-button (click)="expandCard = !expandCard">Muestra menos...</button>
        </mat-card-actions>
    }
    
</mat-card>
<br>

<div style="display:flex; justify-content: space-evenly;margin-top:400px" *ngIf="searchConfigs$ | async as configs">
    <!-- 
        xposition="after"
        yposition="below"
        botName="Ajuntament de Lleida"
        color="red" contrast="white"
        avatar="https://picsum.photos/200"
    -->
    @for (cfg of configs; track cfg.id) {
    <app-trigger 
        greet='Configuració: "{{cfg.name}}"' 
        prompt='Fes les teves consultes.'
        [config]="cfg.uuid"
        [espai]="espai"
        [uuid]="uuid"
        token= "">
    </app-trigger>
    }
</div>
