<!-- template pel diàleg de crear categories -->
<ng-template let-data #topic_input>
    <h3 mat-dialog-title>{{!data?'Nuevo':'Editar'}} tema:</h3>
    <div mat-dialog-content style="display:flex;flex-direction:column;">
        <mat-form-field>
            <mat-label>Clave</mat-label>
            <input matInput required #topic_key [value]="data?.key" [disabled]="!!data">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input matInput required #topic_name [value]="data?.name">
        </mat-form-field>
    </div>
    <div mat-dialog-actions style="display:flex;justify-content: flex-end;">
        <button mat-button color="primary" 
            [mat-dialog-close]="{key:topic_key.value, name:topic_name.value, uuid:data?.uuid}" 
            [disabled]="!topic_key.value || !topic_name.value">
            <mat-icon>{{!!data?'edit':'add'}}</mat-icon>
            {{!!data?'Hecho':'Crear'}}
        </button>
    </div>
</ng-template>

<mat-toolbar  style="justify-content: space-between;">
    <span style="font-weight:600;font-size:large;">Topics</span>
    <span style="flex: 50% 5 0"></span>
    <button mat-button color="primary" aria-label="add topic" (click)="createTopic(topic_input)">
        <mat-icon>add_circle</mat-icon>
        Topic
    </button>
</mat-toolbar>

<mat-list style="background-color: var(--opts-avatar-bgcolor);">
    @for(topic of (topics$ | async); track topic.key) {
        <mat-list-item>
            <span matListItemTitle>Nombre: {{ topic.name }}</span>
            <span matListItemLine>Clave: {{ topic.key }}</span>
            <span matListItemMeta>
                <button mat-icon-button (click)="createTopic(topic_input, topic)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button (click)="deleteTopic(topic)">
                    <mat-icon>delete</mat-icon>
                </button>
            </span>
        </mat-list-item>
        @if($index + 1 < $count ) {
            <mat-divider></mat-divider>
        }
    }
</mat-list>