/// <reference types="aws-sdk" />
import * as AWS from 'aws-sdk';

import { Injectable, Component, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { 
  MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogTitle, MatDialogState, MatDialogActions,
  MatDialogClose
} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { environment } from 'src/environments/environment';
import { LoginComponent } from './login/login.component';

import { 
  Observable, of, delay, mergeMap, from, 
  Subscription, concatMap, EMPTY, tap, 
  retry, throwError, iif, catchError, 
  switchMap, forkJoin, map, 
} from 'rxjs';

const ep = (endpoint:string)=>`${environment.host}/${endpoint}`;

export interface IUserCredentials {
  access_token:string, 
  aws_public_key:string, 
  aws_secret_key:string, 
  username: string,
  uuid: string
}

import { SourcesItem } from './sources/sources-datasource';
import { UsersItem } from './users/users-datasource';
import { UserRoles } from './domain/UserRoles';
import { ConversationType } from './agent-chat/agent-chat.component';
// TODO: replace this with real data from your application
const MOCK_USERS: UsersItem[] = [
  {id: 1, name: 'Hydrogen'},
  {id: 2, name: 'Helium'},
  {id: 3, name: 'Lithium'},
  {id: 4, name: 'Beryllium'},
  {id: 5, name: 'Boron'},
  {id: 6, name: 'Carbon'},
  {id: 7, name: 'Nitrogen'},
  {id: 8, name: 'Oxygen'},
  {id: 9, name: 'Fluorine'},
  {id: 10, name: 'Neon'},
  {id: 11, name: 'Sodium'},
  {id: 12, name: 'Magnesium'},
  {id: 13, name: 'Aluminum'},
  {id: 14, name: 'Silicon'},
  {id: 15, name: 'Phosphorus'},
  {id: 16, name: 'Sulfur'},
  {id: 17, name: 'Chlorine'},
  {id: 18, name: 'Argon'},
  {id: 19, name: 'Potassium'},
  {id: 20, name: 'Calcium'},
];

interface IMetadata {
  doctype: string, //"baixa-padro",
  index_subtype: string, //"descripcio",
  index_type: string, //"padro",
  title: string,
  type: string, // "content" | ...,
  url: string,
}

interface INode {
  node_id: string,
  score: number,
  metadata: IMetadata
}

export interface IRankResponse {
  nodes: Array<INode>,
  query: string,
  rerank: boolean,
  response:string,
  response_mode: string, // "compact",
  similarity: number,
  query_id: number, // <- per enviar al feedback
}

// Ancestre comú pels tipus d'algunes de les respostes d'API 
// hi ha un mètode 'errMap' per mapejar les respostes/errors a conveniencia
interface IErrMsgEnvelope {
  error: number;
  message?: string;
  msg?: string; // nota: hi ha algunes inconsistències en com retorna el missatge
}

// auxiliar del que deriven altres interfaces
export interface IStamped {
  id: number,
  deleted: boolean,
  created_at: string, // "Fri, 21 Jun 2024 10:28:47 GMT",
  updated_at: string, // "Fri, 21 Jun 2024 10:28:47 GMT",
}

export interface IProjecte extends IStamped { 
  enabled: boolean,
  name: string,
  slug: string,
  uuid: string
}

export interface ISpace extends IStamped {
  enabled: boolean,
  name: string,
  uuid: string, 
  indexing_language: string,
  project?: IProjecte | number // <- només algunes respostes d'API l'adjunten
}

export interface ISpaceConfiguration extends IStamped {
  similarity: number,
  guard: boolean,
  mode: string,
  reranker: string,
  index_questions: boolean,
  max_llm_calls: number,
  current_llm_calls: number,
  custom_rag_search: boolean
}

export interface ITopic extends IStamped {
  key: string;
  name: string;
  uuid: string;
  space?:ISpace | number;
}

export interface IHotTopic {
  count: number,
  session: string,
  topic: string
}

interface ITopicsResp extends IErrMsgEnvelope {
  topics?: ITopic[];
  topic?: ITopic;
  trending_topics?: IHotTopic[]
}

interface ITokensResp extends IErrMsgEnvelope {
  token: string;
  tokens: { domain: string, token:string }[]
}

export interface ISession {
  messages: number;
  session: string;
  start_date: string; // "Tue, 09 Jul 2024 08:17:38 GMT";
  topics: string[];
}

interface ISessionsResp extends IErrMsgEnvelope {
  sessions: ISession[]
}

export interface IUserQuery {
  feedback: 'NON_EVALUATED' | 'CORRECT' | 'INCORRECT';
  query: string;
  session: string;
  since_date: string;
}

interface IUserQueryResp extends IErrMsgEnvelope {
  queries: IUserQuery[];
  session: string
}

export interface ISearchConfigurationItem extends IStamped {
  search_configuration: number, // <- referència al parent ISearchConfiguration
  key: string,
  value: number, // <- és l'id d'un ICategoryNode
}

export interface ISearchConfiguration extends IStamped {
  uuid: string,
  name: string,
  space: number,
  items: Array<ISearchConfigurationItem>
}

export interface IQuestion extends IStamped {
  question: string,
  topic: ITopic,
  uuid: string
  space?: ISpace, // <- només algues respostes de l'API l'inclouen
}

export interface IQuestionsResp extends IErrMsgEnvelope {
  questions: Array<IQuestion>
}
export interface ICreateQestionResp extends IErrMsgEnvelope {
  question: IQuestion
}

interface IRespModifiedSource extends IErrMsgEnvelope {
  modified: { [key:string]:any };
  source: SourcesItem
}

export interface ICategoryNode extends IStamped {
  parent?: ICategoryNode,
  enabled: boolean,
  space: ISpace,
  uuid: string,
  value: string,
  children?: ICategoryNode[] // <- No retorna amb l'API, cal reconstruïr l'estructura amb els 'parent'
}

export interface IPrompt extends IStamped {
  key: string,
  content: string,
  expanded: boolean,
}

// Loading component (uify)
@Component({
  selector: 'app-loading',
  standalone:true,
  imports: [CommonModule, MatProgressSpinnerModule, MatDialogTitle, MatDialogContent, MatDialogActions, MatButton ],
  template: `
    <h2 mat-dialog-title>{{data.title}}</h2>
    <mat-dialog-content>
        <p>{{ message$ | async }}</p>
      <mat-spinner></mat-spinner>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="data.cancel">
      <button mat-stroked-button (click)="cancel()" color="primary">{{data.cancel}}</button>
    </mat-dialog-actions>
  `,
  styles: `
    mat-dialog-content
      position: relative
      width: 350px
      height: 250px
      display: flex
      flex-direction: column
      justify-content: center
    p
      position: absolute
      top: 0
    mat-spinner
      margin: 2em auto
    button
      width: 100%
  `
})
export class LoadingComponent {
  message$: Observable<string>;
  constructor(
    private dialogRef: MatDialogRef<LoadingComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: { title:string, message?:string, cancel?:boolean },
  ) {
    const msg = this.data.message;
    if (!!msg) {
      if ( typeof(msg) == 'string') this.message$ = of(msg);
      else this.message$ = msg;
    } else this.message$ = EMPTY;
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}

// diàleg de confirmació
@Component({
  selector: 'app-confirm',
  standalone: true,
  imports: [ CommonModule, MatDialogTitle, MatDialogContent, MatDialogClose, MatDialogActions, MatButton ],
  template: `
  <h3 mat-dialog-title>{{ data.title || 'Confirme:' }}</h3>
  <div mat-dialog-content style="display:flex;flex-direction:column;">
    {{ data.message }}
  </div>
  <div mat-dialog-actions style="display:flex;justify-content: space-between">
    <button mat-button mat-dialog-close>
      {{ data.canceltext || 'Cancelar' }}
    </button>
    <button mat-button color="primary" tabindex="0" mat-dialog-close="confirm" >
      {{ data.oktext || 'De acuerdo' }}
    </button>
  </div>
  `,
  styles: ``
})
export class ConfirmComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message:string, title?:string, oktext?:string, canceltext?:string },
  ) {}
}


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private _isSuperUser: boolean;
  private _userRole: string | undefined;
  private _credentials?: IUserCredentials;
  private _sessionId!:string;
  private resetSession() {
    this._sessionId = btoa(`${Math.random()}`);
  }

  set isSuperUser(superUser: boolean) {
    this._isSuperUser = superUser;
  }

  get isSuperUser() {
    return this._isSuperUser;
  }

  set userRole(role:string | undefined) {
    this._userRole = role;
  }

  get userRole() {
    return this._userRole;
  }

  // Observable que emet el header 'Authorization' amb les credencials
  // disponibles en el moment de la subscripció i es completa. 
  private get _authHeader$() {
    return new Observable<{Authorization?:string}>((observer)=>{
      const header: {[key:string]: string|string[]} = {};
      const token = this._credentials?.access_token;
      if (!!token) header['Authorization'] = `Bearer ${token}`;
      observer.next(header);
      observer.complete();
    });
  }

  get host() {
    return environment.host;
  }

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.resetSession();
  }

  userHasAdminRights(): boolean {
    return this.isSuperUser || (this.userRole != undefined && this.userRole === UserRoles.ADMIN);
  }

  // axiliar per mapejar errors en les respostes que heredin de IErrMsgEnvelope
  // i retornar el tipus envoltat
  errMap<T>(key:string) {
    return map((nxt:IErrMsgEnvelope)=>{
      const { error, msg, message } = nxt;
      if (!!error) throw new Error(message||msg||`${error}`);
      return (<any>nxt)[key] as T;
    })
  }

  // Operador 'pipe' per als Observables que afegeix ui Loading/Toast amb
  // missatges custom, ex: getData().pipe(uify()).subscribe(nxt=>whatever())
  // Si '!!title' evalua a false no es mostra el 'loading';
  uify<T>(
    title:string='Un momento...', message?:string | Observable<string>, cancel?:string
  ) {
    return (observable:Observable<T>) => new Observable<T>(observer => {
      let dialogRef:MatDialogRef<LoadingComponent, string>;
      let _subs:Subscription;

      if (!!title || !!cancel) {
        dialogRef = this.dialog.open(LoadingComponent, {
          data: { title: title, message: message, cancel:cancel },
          ariaModal: true,
          disableClose: true
        });
        dialogRef.afterClosed().subscribe({
          next: retval=>{
            if (retval == 'cancel') {
              observer.error(new Error('User cancelled')); // <- nota: no gestionat
              _subs?.unsubscribe();
            };
            //console.log(`DEBUG ${this.constructor.name} loading closed`, retval)
          },
        })
      }
      _subs = observable.subscribe({
        next: (value)=>observer.next(value),
        error: (err)=>{
          if (!!dialogRef) dialogRef.close('error');
          console.log(`Error ${this.constructor.name}:`, err);
          const dfltErr = "Ha ocurrido un error."
          this.snackBar.open(err.message || dfltErr, 'Entendido', {
            duration: 4000
          });
          observer.error(err);
        },
        complete:()=>{
          if (!!dialogRef) dialogRef.close('complete');
          observer.complete();
        }
      });

      // the return value is the teardown function,
      // which will be invoked when the new
      // Observable is unsubscribed from.
      return ()=>{
        // ens assegurem que el loading es destrueix.
        if (!!dialogRef && dialogRef.getState() == MatDialogState.OPEN) {
          dialogRef.close('destroy');
        }
        _subs.unsubscribe();
      }
    });
  }

  confirmDialog<T>(request:Observable<T>, message:string, title?:string, oktext?:string, canceltext?:string ) {
    let dialogRef:MatDialogRef<ConfirmComponent, string> = this.dialog.open(ConfirmComponent, {
      data: { message, title, oktext, canceltext },
    });
    return dialogRef.afterClosed().pipe(
      switchMap(resp=>resp=='confirm'?request:EMPTY)
    );
  }

  /** 
   * Composa peticions HTTP a partir del header 'Authorization' llegit en el moment de la susbscripció.
   * Captura els errors 401 (unauthorized) per refrescar les credencials i reintentar la petició amb un nou header.
   */
  private authManager<T>(projfn: (opts:{Authorization?:string})=>Observable<T>) {
    // Mostra el LoginComponent i retorna un Observable<any> que es completa
    // en tancar-se el modal, quan les credencials ja s'han refrescat.
    const refreshAuth = ()=>{
      let dialogRef: MatDialogRef<LoginComponent, any> | undefined = this.dialog.getDialogById('_login_');
      if (!dialogRef) dialogRef = this.dialog.open( LoginComponent, { id:'_login_', ariaModal: true, disableClose: true });
      return dialogRef.afterClosed();
    }
    // Obté un Observable auxiliar que rellança tots els errors rebuts excepte el 401.
    // Si l'error és el 401, retornarà un observable que indiqui el moment en que hi ha 
    // disponibles noves credencials, per poder reintentar la petició.
    const checkAuthDelay = (err:HttpErrorResponse, retryCount:number)=>{
      if (err.status!=401) throw err;
      return refreshAuth();
    }
    
    return this._authHeader$.pipe(
      mergeMap(projfn), 
      retry({count:1, delay: checkAuthDelay}), // <- en fallar les credencials amb error 401, espera a que s'hagin renovat i reintenta 1 cop) 
    );
  }

  /** Inicia sessió i refresca les credencials tant de l'API com de l'AWS */
  login(user: string, pass: string) {
    return this.http.post<{
      error:number, msg: string, 
      user: IUserCredentials
    }>(`${this.host}/api/user/login`, {
      username:user, 
      password: pass
    }, {
      headers:{ "Content-Type":"application/json" }
    }).pipe(
      // Mapejo l'error 400 BAD REQUEST amb el missatge d'error retornat per l'endpoint,
      // en la resta d'errades no hi inteferim
      catchError((err)=>{
        if (err.status == 400) throw new Error(err.error.msg); 
        else throw err;
      }),
      tap(({user})=>{
        this._credentials = user;
        AWS.config.update({accessKeyId: user.aws_public_key, secretAccessKey: user.aws_secret_key});
      })
    )
  }

  espais(uuidProj: string) {
    return this.authManager((hdr)=>
      this.http.get<ISpace[]>(`${this.host}/api/project/${uuidProj}/space`, {headers: hdr})
    );
  }

  nouEspai(uuidProj: string, espai:Partial<ISpace>) {
    return this.authManager((hdr)=>
      this.http.post<ISpace>(`${this.host}/api/project/${uuidProj}/space`, espai, {
      headers: { 'Content-Type': 'application/json', ...hdr}
    }))
  }


  projects() {
    return this.authManager(
      (hdr)=>this.http.get<IProjecte[]>(`${this.host}/api/project`, {headers: hdr})
    );
  }


  project(uuid: string) {
    return this.authManager(
      (hdr)=>this.http.get<IProjecte>(`${this.host}/api/project/${uuid}`, {headers: hdr})
    );
  }

  // TODO: potser ha canviat el tipus de dades de retorn !!
  nouProject(proj: Partial<IProjecte>) {
    return this.authManager((hdr)=>
      this.http.post<IProjecte>(`${this.host}/api/project`, proj, {
      headers: { 'Content-Type': 'application/json', ...hdr}
    }));
  }
  editProject(proj:IProjecte) {
    return this.authManager(
      (hdr)=>this.http.put<IProjecte>(
        ep(`api/project/${proj.uuid}`), proj, {
          headers: { "Content-Type": "application/json", ...hdr }
        }
      )
    );
  }
  deleteProject(uuid: string) {
    return this.authManager(
      (hdr)=>this.http.put<IProjecte>(
        ep(`api/project/${uuid}`), { uuid: uuid, deleted: true}, {
          headers: { "Content-Type": "application/json", ...hdr }
        }
      )
    );
  }

  users(projectId?: number | string) {
    // mockup
    return of(MOCK_USERS).pipe(
      delay(600)
    )
  }

  userQueries(uuidProj: string, uuidSpace:string, since: string, session?:string) {
    const params_aux: {since_date:string, session?:string} = { since_date: since };
    if (!!session) params_aux.session = session;
    return this.authManager(
      (hdr)=>this.http.get<IUserQueryResp>(
        ep(`api/project/${uuidProj}/space/${uuidSpace}/user_query`), {
          headers: hdr,
          params: params_aux
        }
      )
    ).pipe(
      this.errMap<IUserQuery[]>('queries')
    );
  }

  uploadBulk(uuidProj: string, uuidEspai: string, data: (File | Blob)[], metadata:{[key:string]:string}={} ) {
    return from(data).pipe(
      concatMap(fob=>{
        const fData = new FormData();
        fData.append('file', fob);
        const md = Object.keys(metadata).map(key=>[key, metadata[key]]);
        md.forEach(([k,v])=>fData.append(k, v));
        if (!!md.length) {
          // si hi hem afegit type:'content' (checkbox indexar por defecto)
          // cal afegir-hi també el topic 'default'
          fData.append('topic', 'default');
        }
        
        return this.authManager((hdr)=>
          this.http.post(`${this.host}/api/index/${uuidProj}/${uuidEspai}`, fData, { headers: hdr })
        );
      })
    )
  }

  modifySource(uuid:string, uuidSpace:string, uuidSource:string, data:any) {
    return this.authManager(
      (hdr)=>this.http.put<IRespModifiedSource>(ep(`api/project/${uuid}/space/${uuidSpace}/source/${uuidSource}`), data, { 
        headers: { 'Content-Type':'application/json', ...hdr } 
      }).pipe(
        this.errMap<{[key:string]:any}>('modified'),
      )
    );
  }
  deleteSource(uuid:string, uuidSpace:string, uuidSource:string) {
    return this.authManager(
      (hdr)=>this.http.delete<IRespModifiedSource>(ep(`api/project/${uuid}/space/${uuidSpace}/source/${uuidSource}`), { 
        headers: hdr 
      })
      .pipe(
        this.errMap<SourcesItem>('source'),
      )
    );
  }

  espaiSources(uuidProj:string, uuidSpace:string) {
    return this.authManager((hdr)=>
      this.http.get<SourcesItem[]>(`${this.host}/api/project/${uuidProj}/space/${uuidSpace}/source`, { headers: hdr })
    )
  }
  espaiTopics(uuidProj:string, uuidSpace:string) {
    return this.authManager(
      (hdr)=>this.http.get<ITopicsResp>(ep(`api/project/${uuidProj}/space/${uuidSpace}/topic`)).pipe(
        this.errMap<ITopic[]>('topics')
      )
    );
  }
  espaiQuestions(uuidProj:string, uuidSpace:string) {
    return this.authManager(
      (hdr)=>this.http.get<IQuestionsResp>(ep(`api/project/${uuidProj}/space/${uuidSpace}/sourcequestion`), { headers: hdr }).pipe(
        this.errMap<IQuestion[]>('questions')
      )
    );
  }
  espaiCategories(uuidProj:string, uuidSpace:string) {
    return this.authManager(
      (hdr)=>this.http.get<{ categories: ICategoryNode[] }>(
        ep(`api/project/${uuidProj}/space/${uuidSpace}/category`), { headers: hdr })
    ).pipe(
      map(({ categories })=>{
        // construeix l'arbre a partir de la llista plana retornada
        const currentCats = categories.filter(c=>!c.deleted);
        const addToParentRecursive = (maybeParent: ICategoryNode, child:ICategoryNode)=>{
          if ( maybeParent.uuid == child.parent?.uuid ) {
            if (Array.isArray(maybeParent.children)) {
              maybeParent.children.push(child);
            } else {
              maybeParent.children = [ child ];
            }
          } else if (Array.isArray(maybeParent.children)) {
            maybeParent.children.forEach(cp=>addToParentRecursive(cp, child));
          }
        }
        return currentCats.reduce(
          (a:ICategoryNode[], b:ICategoryNode)=>{
            if (!b.parent) a.push(b);
            else a.forEach(rootCategory=>addToParentRecursive(rootCategory, b))
            return a;
          }, [] as ICategoryNode[]
        );
      })
    );
  }
  espaiSearchConfig(uuidProj: string, uuidSpace: string) {
    return this.authManager(
      (hdr)=>this.http.get<ISearchConfiguration[]>(
        ep(`api/project/${uuidProj}/space/${uuidSpace}/search/configuration`), { headers: hdr }
      )
    );
  }
  espaiTrendingTopics(uuidProj: string, uuidSpace: string, n_hours=200) {
    return this.authManager(
      (hdr)=>this.http.get<ITopicsResp>(ep(`api/project/${uuidProj}/space/${uuidSpace}/trending_topics`), { 
        headers: hdr,
        params: { hours: n_hours }
      }).pipe(
        this.errMap<IHotTopic[]>('trending_topics')
      )
    );
  }
  espaiSessions(uuidProj:string, uuidSpace: string, startDate:string='2024-01-01') {
    return this.authManager(
      (hdr)=>this.http.get<ISessionsResp>(
        ep(`api/project/${uuidProj}/stats/${uuidSpace}/sessions`), {
          headers: hdr,
          params: { since_date: startDate }
        }
      ).pipe(
        this.errMap<ISession[]>('sessions')
      )
    );
  }
  createCategory(uuidProj:string, uuidSpace:string, name:string, uuidParent?:string) {
    const payload:{ value: string, parent?:string } = { value: name };
    if (!!uuidParent) payload.parent = uuidParent;
    return this.authManager(
      (hdr)=>this.http.post<{ categories: ICategoryNode[] }>(
        ep(`api/project/${uuidProj}/space/${uuidSpace}/category`),
        { categories: [ payload ] },
        { headers: { "Content-Type":"application/json", ...hdr } }
      )
    );
  }
  editCategory(uuidProj:string, uuidSpace: string, payload: Partial<ICategoryNode>) {
    return this.authManager(
      // TODO: return type
      (hdr)=>this.http.put<{category:ICategoryNode}>(
        ep(`api/project/${uuidProj}/space/${uuidSpace}/category/${payload.uuid}`), payload, {
          headers: { "Content-Type": "application/json", ...hdr }
        }
      )
    );
  }
  createSearchConfig(uuidProj:string, uuidSpace:string, cfg:{ name: string, items: Array<{ key: string, value: string }>}) {
    return this.authManager(
      (hdr)=>this.http.post<{id:number, name:string, uuid:string}>(
        ep(`api/project/${uuidProj}/space/${uuidSpace}/search/configuration`), cfg, {
          headers: { "Content-Type":"application/json", ...hdr }
        }
      )
    );
  }
  createQuestion(uuidProj:string, uuidSpace:string, payload: { question:string, topic:string }) {
    return this.authManager(
      (hdr)=>this.http.post<ICreateQestionResp>(
        ep(`api/project/${uuidProj}/space/${uuidSpace}/sourcequestion`), payload,
        { headers: { "Content-Type":"application/json", ...hdr } }
      ).pipe(
        this.errMap<IQuestion>('question')
      )
    );
  }

  createTopic(uuidProj:string, uuidSpace:string, payload:{key:string, name:string}) {
    return this.authManager(
      (hdr)=>this.http.post<ITopicsResp>(
        ep(`api/project/${uuidProj}/space/${uuidSpace}/topic`), payload,
        { headers: { "Content-Type":"application/json", ...hdr } }
      ).pipe(
        this.errMap<ITopic>('topic')
      )
    );
  }
  editTopic(uuidProj:string, uuidSpace:string, payload:{key:string, name:string, uuid:string}) {
    return this.authManager(
      (hdr)=>this.http.put<ITopicsResp>(
        ep(`api/project/${uuidProj}/space/${uuidSpace}/topic/${payload.uuid}`), payload,
        { headers: { "Content-Type":"application/json", ...hdr } }
      ).pipe(
        this.errMap<ITopic>('topic')
      )
    );
  }

  projectSources(uuid: string) {
    return this.espais(uuid).pipe(
      switchMap(espais=>forkJoin(espais.map(e=>this.espaiSources(uuid, e.uuid)))),
      map(es=>es.reduce((a, b)=>a.concat(b), []))
    )
  }

  getBlob(uri: string) {
    return this.http.get(uri, { responseType:'blob' });
  }

  getS3client() {
    return new AWS.S3({ endpoint: environment.s3ep });
  }

  // Envia una questió a l'agent Spark ()
  query(uuid:string, space_uuid:string, question:string, searchCfg:string, token?:string, sessionId?: string) {
    const session = sessionId ? sessionId : this._sessionId;
    console.log(session);
    // funció auxiliar que projecta la "query" en passar-li els auth headers:
    const projectFn = (auth:{[key:string]:string})=>this.http.post<IRankResponse>(
      ep(`api/project/${uuid}/space/${space_uuid}/search/basic`), {
      query:question, search_configuration:searchCfg, session:session
    }, {
      headers: { 'Content-Type':'application/json', ...auth }
    });

    // Per compatibilitat, usem l'antic sistema d'auth si no hi ha token
    if (!token ) return this.authManager(projectFn);
    else return projectFn({"X-Api-Key": token}); 
  }

  feedback(uuid:string, space_uuid:string, queryId: number, val:'correct'|'non_evaluated'|'incorrect', token?:string) {
    const projectFn = (hdr:{[key:string]:string})=>this.http.post(
      ep(`api/project/${uuid}/space/${space_uuid}/feedback/${val}/${queryId}`), {}, {
        headers: { "Content-Type":"application/json", ...hdr }
      }
    );
    if (!token) return this.authManager(projectFn);
    else return projectFn({ "X-Api-Key": token });
  }

  searchConfigurations(uuid:string, space_uuid:string) {
    return this.authManager((auth:{[key:string]:string})=>this.http.get<ISearchConfiguration[]>(
      ep(`api/project/${uuid}/space/${space_uuid}/search/configuration`), { headers: auth }
    )).pipe(
      this.uify('Preview', 'Obteniendo configuraciones...')
    );
  }

  spaceConfiguration(uuid:string, space_uuid: string) {
    return this.authManager((auth:{[key:string]:string})=>this.http.get<{error: number, configuration: ISpaceConfiguration}>(
      ep(`api/project/${uuid}/space/${space_uuid}/configuration`), { headers: auth }
    )).pipe(
      this.uify('Preview', 'Obteniendo configuracion de proyecto...')
    );
  }

  editSpaceConfiguration(uuid: string, space_uuid: string, configuration: ISpaceConfiguration) {
    return this.authManager((auth:{[key:string]:string})=>this.http.put<{error: number, configuration: ISpaceConfiguration}>(
      ep(`api/project/${uuid}/space/${space_uuid}/configuration`), configuration, {
      headers: { 'Content-Type':'application/json', ...auth }
    })).pipe(
      this.uify('Preview', 'Editando configuración de espacio...')
    );;
  }

  // Reset de la sessió (pel mode "chat")
  resetChat() {
    return this.authManager((auth:{[key:string]:string})=>this.http.post<{error:number}>(
      ep('session/reset'), { session: this._sessionId }, {
      headers: { 'Content-Type':'application/json', ...auth }
    }).pipe(tap({next:_=>this.resetSession()})));
  }

  getSpacePrompts(uuid: string, space_uuid: string) {
    return this.authManager((auth:{[key:string]:string})=>this.http.get<IPrompt[]>(
      ep(`api/project/${uuid}/space/${space_uuid}/configuration/prompt`), { headers: auth }
    )).pipe(
      this.uify('Preview', 'Obteniendo prompts...')
    );
  }

  editSpacePrompt(uuid: string, space_uuid: string, prompt: IPrompt) {
    return this.authManager((auth:{[key:string]:string})=>this.http.patch<{prompt: IPrompt}>(
      ep(`api/project/${uuid}/space/${space_uuid}/configuration/prompt/${prompt.id}`), prompt, {
      headers: { 'Content-Type':'application/json', ...auth }
    })).pipe(
      this.uify('Preview', 'Editando prompt...')
    );
  }

  getSpaceUserRole(uuidProj: string, uuidSpace: string) {
    return this.authManager((auth:{[key:string]:string})=>this.http.get<{ error: number, msg: string, role: string }>(
      ep(`api/project/${uuidProj}/space/${uuidSpace}/role/${this._credentials!!.uuid}`), { headers: auth }
    ));
  }

  isUserSuperUser() {
    return this.authManager((auth:{[key:string]:string})=>this.http.get<{ error: number, msg: string, is_superuser: boolean }>(
      ep(`api/role/${this._credentials?.uuid}`), { headers: auth }
    )).pipe(
      this.uify('Preview', 'Obteniendo estado usuario...')
    );
  }

  loadTrendingTopics(uuidProj: string, uuidSpace: string, session: string | undefined = undefined) {
    let sessionUrl = session != undefined ? `?session=${session}` : '';
    return this.authManager((auth:{[key:string]:string})=>this.http.post<{ error: number, msg: string }>(
      ep(`api/project/${uuidProj}/space/${uuidSpace}/trending_topics` + sessionUrl), { headers: auth }
    )).pipe(
      this.uify('Preview', 'Cargando trending topics ...')
    );
  }

  getTrendingTopicsBySession(uuidProj: string, uuidSpace: string, session: string) {
    return this.authManager((auth:{[key:string]:string})=>this.http.get<{ error: number, msg: string, trending_topics: { session: string, topic: string }[] }>(
      ep(`api/project/${uuidProj}/space/${uuidSpace}/trending_topics/all?session=` + session), { headers: auth }
    )).pipe(
      this.uify('Preview', 'Cargando trending topics para la sesión...')
    );
  }

  getSessionMessagesHistory(uuidProj: string, uuidSpace: string, session: string) {
    return this.authManager((auth:{[key:string]:string})=>this.http.get<{ error: number, msg: string, messages: { query: string, response: string }[] }>(
      ep(`api/project/${uuidProj}/space/${uuidSpace}/session/` + session), { headers: auth }
    )).pipe(
      this.uify('Preview', 'Cargando mensajes de la sesión...')
    );
  }

  getAllConversation(uuidProj: string, uuidSpace: string, session: string | undefined, force: boolean) {
    if(session == undefined) { session = this._sessionId }
    return this.authManager((auth:{[key:string]:string})=>this.http.get<{ error: number, msg: string, conversations: ConversationType[] }>(
      ep(`api/project/${uuidProj}/space/${uuidSpace}/conversation/` + session + '?force=' + force.toString()), { headers: auth }
    )).pipe(
      this.uify('Preview', 'Cargando mensajes de la sesión...')
    ); 
  }

  getAllConversationNoLoader(uuidProj: string, uuidSpace: string, session: string | undefined, force: boolean) {
    if(session == undefined) { session = this._sessionId }
    return this.authManager((auth:{[key:string]:string})=>this.http.get<{ error: number, msg: string, conversations: ConversationType[] }>(
      ep(`api/project/${uuidProj}/space/${uuidSpace}/conversation/` + session + '?force=' + force.toString()), { headers: auth }
    ))
  }

  postConversationMessage(uuidProj: string, uuidSpace: string, session: string | undefined, message: string, sender: string) {
    if(session == undefined) { session = this._sessionId }
    return this.authManager((auth:{[key:string]:string})=>this.http.post<{ error: number, msg: string }>(
      ep(`api/project/${uuidProj}/space/${uuidSpace}/conversation/` + session), { message: message, sender: sender }, { headers: auth }
    ));
  }

  setSessionNeedHelp(uuidProj: string, uuidSpace: string, session: string | undefined) {
    if(session == undefined) { session = this._sessionId }
    return this.authManager((auth:{[key:string]:string})=>this.http.post<{ error: number, msg: string }>(
      ep(`api/project/${uuidProj}/space/${uuidSpace}/conversation/` + session + '/need_help'), {}, { headers: auth }
    ));
  }

  setSessionEnded(uuidProj: string, uuidSpace: string, session: string | undefined) {
    if(session == undefined) { session = this._sessionId }
    return this.authManager((auth:{[key:string]:string})=>this.http.post<{ error: number, msg: string }>(
      ep(`api/project/${uuidProj}/space/${uuidSpace}/conversation/` + session + '/end'), {}, { headers: auth }
    ));
  }

  getApiTokens(uuidProj: string, uuidSpace:string) {
    return this.authManager((auth)=>this.http.get<ITokensResp>(
      ep(`api/project/${uuidProj}/space/${uuidSpace}/api_token`), {
        headers: auth
    }).pipe(
      this.errMap<{ domain: string, token:string }[]>('tokens')
    ));
  }
  createApiToken(uuidProj:string, uuidSpace:string, domain:string) {
    return this.authManager((auth)=>this.http.post<ITokensResp>(
      ep(`api/project/${uuidProj}/space/${uuidSpace}/api_token`), { domain }, {
        headers: { 'Content-Type':'application/json', ...auth }
    }).pipe(this.errMap<string>('token')));
  }

}
