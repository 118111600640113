import { ModuleWithProviders, NgModule, inject, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';

import { TriggerComponent } from 'src/app/spark-chat-component/trigger/trigger.component';
import { AgentChatComponent } from './agent-chat.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AgentChatComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
    MatIconModule,
  ],
  exports: [ AgentChatComponent ]
})
export class AgentChatModuleComponent {}
