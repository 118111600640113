<mat-toolbar>
    <!-- <span style="flex: 1 1 auto"></span> -->
     <mat-form-field class="custom" appearance="outline">
        <mat-label>dominio</mat-label>
        <input matInput #domainName>
     </mat-form-field>
    <button mat-icon-button [disabled]="!domainName.value" (click)="addToken(domainName.value)">
        <mat-icon>add</mat-icon>
    </button>
</mat-toolbar>
<mat-list *ngIf="tokens$ | async as toks">
    @for(token of toks; track token) {
        <mat-list-item>
            <button mat-icon-button matListItemIcon>
                <mat-icon>delete</mat-icon>
            </button>
            <h3 matListItemTitle>{{ token.domain || 'domain'}}</h3>
            <p matListItemLine>{{ token.token || 'token'}}</p>
        </mat-list-item>
    }
</mat-list>
