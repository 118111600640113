<mat-list style="background-color: var(--lighter-bgcolor);color:var(--text-color); display:flex; padding:5px">
    <mat-list-item class="button">
        <button mat-button color="primary" (click)="exportTable()">Export Table</button>
    </mat-list-item>
    <mat-list-item class="button">
        <button mat-button color="primary" (click)="loadSessionTopics()">Load Topics</button>
    </mat-list-item>
</mat-list>
<mat-tab-group>
    <mat-tab label="CHAT">
        @if (session) {
            <app-agent-chat [uuid]="uuid" [espai]="espai" [sessionUuid]="session" [view]='MessageSenders.AGENT'></app-agent-chat>
        }
    </mat-tab>
    <mat-tab label="MENSAJES">
        <mat-table matTableExporter #exporter="matTableExporter" matSort #sTable="matTable" #sSort="matSort" aria-label="Sessions"
            class="full-width-table" style="background-color:  var(--background-color); padding:24px">
        
            <!-- search input -->
            <ng-container matColumnDef="search">
            <mat-header-cell *matHeaderCellDef style="flex-basis:calc(40%); flex-shrink: 2; border-bottom:none; padding: 1em 0;padding-left:0">
                <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 2 0;text-transform: none;">
                <mat-label>Search queries</mat-label>
                <mat-icon matTextPrefix style="position:relative;top:+.33em;">search</mat-icon>
                <input matInput placeholder="..." [formControl]="searchControl" type="search">
                </mat-form-field>
            </mat-header-cell>
            </ng-container>
        
            <!-- feedback filter -->
            <ng-container matColumnDef="feedback-filter">
            <mat-header-cell *matHeaderCellDef style="flex-basis:20%; border-bottom:none; padding: 1em 0">
                <mat-form-field appearance="outline" class="custom" style="flex:calc(100% - 1em) 0 0;text-transform: none;">
                <mat-label>Feedback</mat-label>
                <mat-select [formControl]="feedbackSelection" multiple>
                    @for (filter of [
                        { key: 'CORRECT', value: 'Correcto' }, 
                        { key: 'INCORRECT', value: 'incorrecto' },
                        { key:'NON_EVALUATED', value: 'No evaluado' }]; track filter.key) {
                        <mat-option [value]="filter.key">{{filter.value}}</mat-option>
                    }
                </mat-select>
                </mat-form-field>
            </mat-header-cell>
            </ng-container>
        
            <!-- paginator -->
            <ng-container matColumnDef="paginator">
            <mat-header-cell *matHeaderCellDef style="flex-basis:40%;border-bottom:none; padding: 1em 0">
                <mat-paginator style="background-color: transparent;"
                    [length]="dataSource.data.length"
                    [pageIndex]="0"
                    [pageSize]="15"
                    [pageSizeOptions]="[5, 10, 15, 20]"
                    aria-label="Select page">
                </mat-paginator>
            </mat-header-cell>
            </ng-container>
        
            <!-- Query Column -->
            <ng-container matColumnDef="query">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Query</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.query}}</mat-cell>
            <mat-footer-cell *matFooterCellDef>Query</mat-footer-cell>
            </ng-container>
        
            <!-- Date Column -->
            <ng-container matColumnDef="data">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Inicio</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row.since_date | date:'mediumDate':'':'es-ES' }}</mat-cell>
            <mat-footer-cell *matFooterCellDef>Inicio</mat-footer-cell>
            </ng-container>
        
            <!-- Feedback Column -->
            <ng-container matColumnDef="feedback">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Feedback</mat-header-cell>
            <mat-cell *matCellDef="let row">
                @switch(row.feedback) {
                    @case('CORRECT') {
                        <mat-icon class="fbicon">thumb_up</mat-icon> Positiva
                    }
                    @case('INCORRECT') {
                        <mat-icon class="fbicon">thumb_down</mat-icon> Negativa
                    }
                    @case('NON_EVALUATED') {
                        <mat-icon class="fbicon" >sentiment_neutral</mat-icon> Neutral
                    }
                }
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>Feedback</mat-footer-cell>
            </ng-container>
        
        
            <mat-header-row *matHeaderRowDef="['search', 'feedback-filter', 'paginator'].concat(displayedColumns); sticky: true;" 
            style="text-transform: uppercase;background-color: var(--background-color);flex-wrap:wrap;">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true;"
            style="text-transform: uppercase;background-color: var(--background-color);">
            </mat-footer-row>
        
        </mat-table>
    </mat-tab>
    
    <mat-tab label="DETALLES">
        <mat-list style="background-color: var(--lighter-bgcolor);color:var(--text-color); display:flex; padding:24px">
            <mat-list-item>
              <span matListItemTitle>CONSULTES TOTALS</span>
              <span matListItemLine>{{ numQueries }}</span>
            </mat-list-item>
            <mat-list-item>
              <span matListItemTitle>MISSATGES TOTALS</span>
              <span matListItemLine>
                @switch(generalFeedback) {
                    @case('CORRECT') {
                        <mat-icon class="fbicon">thumb_up</mat-icon> Positiva
                    }
                    @case('INCORRECT') {
                        <mat-icon class="fbicon">thumb_down</mat-icon> Negativa
                    }
                    @case('NON_EVALUATED') {
                        <mat-icon class="fbicon" >sentiment_neutral</mat-icon> Neutral
                    }
                }
                </span>
            </mat-list-item>
        </mat-list>
    </mat-tab>

    <mat-tab label="TOPICS">
        <mat-list style="background-color: var(--lighter-bgcolor);color:var(--text-color); display:flex; padding:24px; flex-wrap: wrap;">
            @for (topic of topics; track $index) {
                <mat-chip class="colored-chip">{{topic.topic}}</mat-chip>
            }
        </mat-list>
    </mat-tab>
</mat-tab-group>
  