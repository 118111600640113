import { Component, Input, ViewChild, AfterViewInit, OnDestroy, TemplateRef, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { HotTopicsDataSource, IGroupedHTopic } from './hot-topics.datasource';
import { ApiService, IProjecte, ISpace, IHotTopic } from 'src/app/api.service';

import { Subscription, combineLatest, startWith, debounceTime } from 'rxjs';

@Component({
  selector: 'app-hot-topics',
  templateUrl: './hot-topics.component.html',
  styleUrl: './hot-topics.component.scss'
})
export class HotTopicsComponent implements AfterViewInit, OnDestroy {

  sessions: string[] = []

  @Input() uuid: string; // <- uuid del projecte (param url)
  @Input() espai: string; // <- uuid de l'espai
  @Input() set detail(dtl:ISpace) {
    this.api.espaiTrendingTopics(this.uuid, dtl.uuid).pipe(
      this.api.uify('Obteniendo tendencias...', dtl.name)
    ).subscribe({
      next: (nxt)=>{
        this.htDataSource.data = nxt;
        this._ghot_topics = this.htDataSource.data.sort((a, b)=>a.count<b.count?-1:a.count==b.count?0:-1)
        this.sessions = nxt.map(
          el=>el.session
        ).sort().filter((el, idx, ary)=>!idx||ary[idx-1]!=el); // <- elimina repetits
      }
    });
  }

  @ViewChild('tPaginator') tPaginator!: MatPaginator;
  @ViewChild('tSort') tSort!: MatSort;
  @ViewChild('topicTable') tTable!: MatTable<IGroupedHTopic>;
  
  htDataSource = new HotTopicsDataSource();
  displayedColumns = [ 'topics', 'count', 'sessions', ];
  searchControl = new FormControl('');
  sessionControl = new FormControl('');

  private _sub: Subscription;
  private _ghot_topics: IGroupedHTopic[] = []; // <- ordenat per 'count'
  get hottestTopic() {
    if (!this._ghot_topics.length) return '';
    return this._ghot_topics[this._ghot_topics.length-1].topic;
  }
  get coldestTopic() {
    if (!this._ghot_topics.length) return '';
    return this._ghot_topics[0].topic;
  }

  constructor(
    private api: ApiService
  ) {
    // implementa el filtrat
    this._sub = combineLatest([
      this.searchControl.valueChanges.pipe(startWith(''), debounceTime(350)),
      this.sessionControl.valueChanges.pipe(startWith([]))
    ]).subscribe({
      next: ([search, sessions])=>{
        let retval: IGroupedHTopic[] = this._ghot_topics || [];
        if (Array.isArray(sessions) && sessions.length) {
          retval = retval.filter(ht=>!!sessions.find(sess=>ht.sessions.includes(sess)));
        }
        if (search?.length) {
          const regexp = new RegExp(`.*${search}.*`,'i'); // <- cerca insensible a majúscules
          retval = retval.filter(ht=>!!ht.topic.match(regexp))
        }
        this.htDataSource.reset(retval);
      }
    });
  }

  ngAfterViewInit(): void {
      this.htDataSource.sort = this.tSort;
      this.htDataSource.paginator = this.tPaginator;
      this.tTable.dataSource = this.htDataSource;
  }

  ngOnDestroy(): void {
      this._sub.unsubscribe();
  }

  loadTopics(): void {
    this.api.loadTrendingTopics(this.uuid, this.espai).subscribe({
      next: (nxt)=> {
      }
    });
  }

}
