<!-- template pel diàleg de creació d'espais -->
<ng-template #create_space>
    <h2 mat-dialog-title>Nuevo espacio</h2>
    <mat-dialog-content>
      <p>Introduce los datos de configuración del espacio:</p>
      <form style="display: flex; flex-direction: column;">
        <mat-form-field appearance="outline">
          <mat-label>Nobre del espacio</mat-label>
          <input matInput cdkFocusInitial required #sname>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline">
          <mat-label>Tipo</mat-label>
          <mat-select required #stype>
            @for (filter of spaceTypeFilters; track filter) {
              <mat-option [value]="filter">{{this.filterNames[filter]}}</mat-option>
            }
          </mat-select>
        </mat-form-field> -->
      </form>
    </mat-dialog-content>
    <mat-dialog-actions style="justify-content: flex-end;">
      <button mat-button color="primary" [disabled]="!sname.value" 
        [mat-dialog-close]="{ name:sname.value }">
        Crear
      </button>
    </mat-dialog-actions>
  </ng-template>
  
  <mat-toolbar style="background-color: var(--background-color);color:var(--text-color);margin-top: 16px;">
    <mat-form-field appearance="outline" class="custom" style="flex:auto 2 0">
      <mat-label>Search spaces</mat-label>
      <mat-icon matTextPrefix style="position:relative;top:+.33em;">search</mat-icon>
      <input matInput placeholder="..." type="search" [formControl]="searchControl">
    </mat-form-field>
    <!-- <mat-form-field appearance="outline" class="custom" style="flex:auto 0 0">
      <mat-label>Filtrar</mat-label>
            <mat-select [formControl]="filterSelection" multiple>
              @for (filter of spaceTypeFilters; track filter) {
                <mat-option [value]="filter">{{this.filterNames[filter]}}</mat-option>
              }
            </mat-select>
    </mat-form-field> -->
    <span style="flex:auto 2 2"><!--spacer--></span>
    @if ( userHasAdminRights() ) {
      <button mat-flat-button color="primary" (click)="createSpaceDialog(create_space)"
        style="height: 54px; border-radius:27px; padding: 0 1.5em; flex:auto 0 0">
        <mat-icon>add</mat-icon> Nuevo espacio
      </button>
    }
  </mat-toolbar>
  
  <div class="grid-container">
    <mat-grid-list [cols]="currentCols" rowHeight="5:2" gutterSize="16px">
      @for (card of cards$ | async; track card.id) {
        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <mat-card class="dashboard-card">
            <!-- <mat-card-header>
              <mat-card-title style="text-transform: uppercase;font-size: 14px;margin:.8em 0">
                {{filterNames[card.tipus]}}
              </mat-card-title>
            </mat-card-header> -->
            <mat-card-content class="dashboard-card-content">
              <div>{{card.name}}</div>
            </mat-card-content>
            <mat-card-actions>
              <!-- Nota: passem l'IProjecte actual i el llistat d'espais al state de la navegació -->
            <button mat-button color="primary" [routerLink]="[card.uuid, 'general']" [state]="{project:detail, espais:cards$.value}"
              style="font-size:14px; width: 100%;justify-content: space-between;flex-direction: row-reverse;">
              ACCEDER <mat-icon aria-label="ir">arrow_forward</mat-icon>
            </button>
            </mat-card-actions>
          </mat-card>
        </mat-grid-tile>
      }
    </mat-grid-list>
  </div>