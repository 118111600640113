import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService, IProjecte, ISpace, ISpaceConfiguration } from 'src/app/api.service';

@Component({
  selector: 'app-space-configuration',
  standalone: false,
  templateUrl: './space-configuration.component.html',
  styleUrl: './space-configuration.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SpaceConfigurationComponent {

  configurationForm: FormGroup = new FormGroup({
    similarity: new FormControl(0.5),
    guard: new FormControl(false),
    mode: new FormControl('default'),
    reranker: new FormControl('default'),
    indexQuestions: new FormControl(false),
    maxLlmCalls: new FormControl(10),
    currentLlmCalls: new FormControl(0),
    customRagSearch: new FormControl(false),
  });

  @Input() uuid?: string;
  @Input() espai?: string;

  @Input() set projecte(proj:IProjecte) {
    if (!proj) return;
  }

  @Input() set detail(esp: ISpace) {
    if (!esp || !this.uuid) return;
  }
  
  constructor(private apiService: ApiService) {
  }

  ngOnInit() {
    this.fetchSpaceConfiguration();
  }

  private fetchSpaceConfiguration() {
    this.apiService.spaceConfiguration(this.uuid!!, this.espai!!).subscribe({
      next: (config) => {
        this.setFormFieldsWithConfiguration(config);
      }
    });
  }

  editConfiguration() {
    this.apiService.editSpaceConfiguration(
      this.uuid!!,
      this.espai!!,
      this.getFormFieldsConfiguration(),
    ).subscribe({
      next: (config) => {
        this.setFormFieldsWithConfiguration(config);
      },
    });
  }

  setFormFieldsWithConfiguration(config: {error: number, configuration: ISpaceConfiguration}) {
    this.configurationForm.setValue({
      similarity: config.configuration.similarity,
      guard: config.configuration.guard,
      mode: config.configuration.mode,
      reranker: config.configuration.reranker,
      indexQuestions: config.configuration.index_questions,
      maxLlmCalls: config.configuration.max_llm_calls,
      currentLlmCalls: config.configuration.current_llm_calls,
      customRagSearch: config.configuration.custom_rag_search,
    });
  }

  getFormFieldsConfiguration(): ISpaceConfiguration {
    return {
      similarity: this.configurationForm.get('similarity')?.value,
      guard: this.configurationForm.get('guard')?.value,
      mode: this.configurationForm.get('mode')?.value,
      reranker: this.configurationForm.get('reranker')?.value,
      index_questions: this.configurationForm.get('indexQuestions')?.value,
      max_llm_calls: this.configurationForm.get('maxLlmCalls')?.value,
      current_llm_calls: this.configurationForm.get('currentLlmCalls')?.value,
      custom_rag_search: this.configurationForm.get('customRagSearch')?.value,
    } as ISpaceConfiguration;
  }

  restartConfiguration() {
    this.fetchSpaceConfiguration();
  }

}
