import { Component, Input, ViewChild, OnInit, OnChanges, AfterViewInit, OnDestroy, TemplateRef, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

import { QuestionsDataSource } from './questions-datasource';
import { ApiService, IQuestion, ITopic } from 'src/app/api.service';
import { environment } from 'src/environments/environment';
import { Subscription, startWith, debounceTime, combineLatest } from 'rxjs';

@Component({
  selector: 'app-question-editor',
  templateUrl: './question-editor.component.html',
  styleUrl: './question-editor.component.scss'
})
export class QuestionEditorComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private _subs: Subscription;

  @Input() questions?:IQuestion[];
  @Input() topics?: Partial<ITopic>[];

  @ViewChild('questionPaginator') qPaginator!: MatPaginator;
  @ViewChild('questionSort') qSort!: MatSort;
  @ViewChild('questionTable') qTable!: MatTable<IQuestion>;
  
  questionsDataSource = new QuestionsDataSource();
  displayedQuestionColumns = [ 'questions', 'topic', 'date', /*'sources'*/ ];
  questionSearchControl = new FormControl('');
  topicSelectionControl = new FormControl('');

  constructor(
    private aroute: ActivatedRoute,
    private api: ApiService,
    private dialog: MatDialog,
  ) {
    // implementa el filtrat
    this._subs = combineLatest([
      this.questionSearchControl.valueChanges.pipe(startWith(''), debounceTime(350)),
      this.topicSelectionControl.valueChanges.pipe(startWith([]))
    ]).subscribe({
      next: ([search, topics])=>{
        let retval: IQuestion[] = this.questions || [];
        if (Array.isArray(topics) && topics.length) {
          this.debug({keys:topics, qtopics:retval.map(q=>q.topic)}, 'cerca per topic:')
          retval = retval.filter(q=>!!topics.find(tkey=>q.topic.key==tkey));
        }
        if (search?.length) {
          const regexp = new RegExp(`.*${search}.*`,'i'); // <- cerca insensible a majúscules
          retval = retval.filter(q=>!!q.question?.match(regexp))
        }
        this.questionsDataSource.data = retval;
      }
    });
  }

  ngOnInit(): void {
      this.questionsDataSource.data = this.questions || [];
  }

  ngAfterViewInit(): void {
    this.questionsDataSource.sort = this.qSort;
    this.questionsDataSource.paginator = this.qPaginator;
    this.qTable.dataSource = this.questionsDataSource;
    this.questionsDataSource.data = this.questions || [];
  }

  ngOnChanges(changes: SimpleChanges): void {
      const qchange = changes['questions'];
      if (!!qchange && !qchange.firstChange) {
        this.questionsDataSource.data = qchange.currentValue;
      }
      // const tchange = changes['topics'];
      // if (tchange) this.debug(tchange, 'ngOnChanges on topics:')
  }

  ngOnDestroy(): void {
      this._subs.unsubscribe();
  }

  createQ(template:TemplateRef<any>) {
    const dref = this.dialog.open<any,null,{question:string,topic:string}|undefined>(template, {
      width:'600px'
    });
    dref.afterClosed().subscribe({
      next: (payload)=>{
        if (!payload) return;
        const { uuid, espai } = this.aroute.snapshot.params;
        this.api.createQuestion(uuid, espai, payload).subscribe({
          next:(resp:IQuestion)=>{
            this.questions=[resp].concat(this.questions || []);
            // TODO: aquí caldria aplicar el filtrat!
            this.questionsDataSource.data = this.questions;
          }
        });
      }
    })
  }

  debug(sth:any, tag="") {
    if (environment.production) return; // <- No mostrar a producció
    console.log(`DEBUG ${this.constructor.name}, ${tag}`, sth);
  }
}
