import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, IProjecte } from 'src/app/api.service';
import { SignalService } from 'src/app/nav-frame/signal.service';


@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrl: './project-settings.component.scss'
})
export class ProjectSettingsComponent implements OnInit {

  @Input('detail') projecte!:IProjecte;

  editForm = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    enabled: new FormControl<boolean>(false)
  });

  constructor(
    private api: ApiService,
    private signal: SignalService,
    private router: Router,
    private aroute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
      this.editForm.reset({
        name: this.projecte.name,
        enabled: this.projecte.enabled
      });
  }

  editProj() {
    const changes = this.editForm.value;
    const payload = { ...this.projecte };
    payload.name = changes.name!
    payload.enabled = !!changes.enabled;
    this.api.editProject(payload).pipe(
      this.api.uify('Enviando cambios...', 'Editar proyecto:')
    ).subscribe({
      next:(nxt)=>{
        this.signal.title = payload.name;
      }
    })
  }

  deleteProj() {
    this.api.confirmDialog(
      this.api.deleteProject(this.projecte.uuid).pipe(this.api.uify('Eliminando proyecto....')),
      "¿Quieres eliminar este proyecto?"
    ).subscribe({
      next:(nxt)=>{
        console.log(`DEBUG ${this.constructor.name} deleteProj returns:`, nxt);
        this.router.navigate(['../..'], { relativeTo: this.aroute })
      }
    })
  }

}
