<form style="display:flex; flex-direction:column" [formGroup]="configurationForm" (ngSubmit)="editConfiguration()">
    <div class="form-fields">
        <mat-form-field>
            <mat-label>Similarity</mat-label>
            <input matInput type="text" required formControlName="similarity">
        </mat-form-field>
        <div class="checkbox-container">
            <mat-label>Guard</mat-label>
            <mat-checkbox matInput formControlName="guard"></mat-checkbox>
        </div>
        <mat-form-field>
            <mat-label>Mode</mat-label>
            <mat-select formControlName="mode">
                <mat-option [value]="'chat'">Chat</mat-option>
                <mat-option [value]="'basic'">Basic</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Reranker</mat-label>
            <mat-select formControlName="reranker">
                <mat-option [value]="'rerank-multilingual-v3.0'">Cohere Multilingual</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="checkbox-container">
            <mat-label>Index Questions</mat-label>
            <mat-checkbox matInput formControlName="indexQuestions"></mat-checkbox>
        </div>
        <mat-form-field>
            <mat-label>Max LLM Calls</mat-label>
            <input matInput type="text" required formControlName="maxLlmCalls">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Current LLM Calls</mat-label>
            <input matInput type="text" required formControlName="currentLlmCalls">
        </mat-form-field>
        <div class="checkbox-container">
            <mat-label>Custom Rag Search</mat-label>
            <mat-checkbox matInput formControlName="customRagSearch"></mat-checkbox>
        </div>
    </div>
    <div class="submit">
        <button mat-fab extended type="submit"> 
            <mat-icon>done</mat-icon>
            Aceptar
        </button>
        <button mat-fab extended type="button" (click)="restartConfiguration()"> 
            <mat-icon>cancel</mat-icon>
            Cancelar
        </button>
    </div>
</form>