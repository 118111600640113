import { Component, ViewChild, Input, OnInit, AfterViewInit, ViewEncapsulation, TemplateRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { EmbeddedAssetsService } from './embedded-asssets.service';
import { ApiService, IRankResponse } from 'src/app/api.service';
import { Observable, map, catchError, tap, lastValueFrom, delay, of } from 'rxjs';

type horiz_pos = 'before' | 'after';
type verti_pos = 'above' | 'below';
type aux_css_params = 'top' | 'left' | 'bottom' | 'right' | 'transform';

type IFollowUpOptions = Array<{num:number, text:string, query?:string, doctype?:string}>;

interface IInteractionResponse extends Partial<IRankResponse> {
  response: string,
  followUp?: IFollowUpOptions,
  feedback?: 'correct'|'non_evaluated'|'incorrect'
}

interface IInteraction extends Partial<IInteractionResponse> {
  request: string;
  response$: Observable<IInteractionResponse|never>;
}

const DEFAULT_QUESTIONS:IFollowUpOptions = [
  /*{num:1, text:"Vull sol·licitar un certificat d'empadronament."},
  {num:2, text:"Vull donar-me d'alta al Padró Municipal d'Habitants."},
  {num:3, text:"Vull canviar el meu domicili al Padró Municipal d'Habitants."},
  {num:4, text:"Vull realitzar un altre tràmit."},*/
];

@Component({
  selector: 'app-trigger',
  templateUrl: './trigger.component.html',
  styleUrl: './trigger.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  providers:[ EmbeddedAssetsService ]
})
export class TriggerComponent implements OnInit, AfterViewInit {

  @Input() color: string = "#FF7A00";
  @Input() contrast: string = 'white';
  @Input() greet: string = "Hola! Soc el xatbot de Use It";
  @Input('prompt') line: string = "Et puc ajudar en alguna cosa?";
  @Input() avatar: string = "spark";
  @Input('botname') botName:string = "Use It";
  @Input() xposition:horiz_pos = "before";
  @Input() yposition:verti_pos = "above";

  @Input('uuid') projectUUID!: string;
  @Input('espai') espaiUUID!: string;
  @Input('config') configUUID!: string;
  @Input() token: string; // <- IMPORTANT: sense aquest token configurat no funcionarà el xat!!

  @ViewChild(MatMenuTrigger) promptTrigger: MatMenuTrigger;

  // auxiliar per posicionar la punta de la bombolla inicial
  bubblePointPosition: Partial<Record<aux_css_params,string>> = {}
  availableQuestions = DEFAULT_QUESTIONS;
  messages: IInteraction[] = [];

  get welcome() {
    return `**${this.greet}**. Durant la nostra conversa podràs demanar-me qualsevol dubte que tinguis o seleccionar l'opció que més t'interessi.
    ${this.line}`
  }

  // auxiliar que evita que els botons del toolbar robin el focus a l'input.
  public delay = of(true).pipe(delay(200));
  

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    public assets: EmbeddedAssetsService
  ) {}

  ngOnInit(): void {
    // posiciona la punta de la bombolla inicial
    if ( this.xposition == 'before') {
      if (this.yposition == 'above') {
        this.bubblePointPosition.bottom='0';
        this.bubblePointPosition.right='.5em';
        this.bubblePointPosition.transform='translate(-25px, 12.5px) rotate(45deg)'
      } else {
        this.bubblePointPosition.top='0';
        this.bubblePointPosition.right='.5em';
        this.bubblePointPosition.transform='translate(-25px, -12.5px) rotate(45deg)'
      }
    } else {
      if (this.yposition == 'above') {
        this.bubblePointPosition.bottom='0';
        this.bubblePointPosition.left='.5em';
        this.bubblePointPosition.transform='translate(25px, 12.5px) rotate(45deg)'
      } else {
        this.bubblePointPosition.top='0';
        this.bubblePointPosition.left='.5em';
        this.bubblePointPosition.transform='translate(25px, -12.5px) rotate(45deg)'
      }
    }
      
  }
  ngAfterViewInit(): void {
      // en engegar mostra el prompt
      if (this.promptTrigger) setTimeout(()=>this.promptTrigger.openMenu(),1);
  }

  toggleDialog(origin: DOMRect, template: TemplateRef<any>) {
    const dref = this.dialog.getDialogById('_chat_');
    if (dref) return dref.close();

    // TODO: parametritzar mides?
    const [ height, width ] = [ 600, 420 ];

    // posiciona el diàleg del xat segons els paràmetres d'entrada
    const pos: Partial<Record<'bottom'|'left'|'right'|'top', string>> = {};

    if ( this.xposition == 'before') {
      if (this.yposition == 'above') {
        pos.top = `${origin.top - height}px`,
        pos.left = `${origin.left + origin.width - width}px`
      } else {
        pos.top=`${origin.top + origin.height}px`;
        pos.left= `${origin.left + origin.width - width}px`;
      }
    } else {
      if (this.yposition == 'above') {
        pos.top = `${origin.top - height}px`,
        pos.left = `${origin.left}px`
      } else {
        pos.top=`${origin.top + origin.height}px`;
        pos.left = `${origin.left}px`
      }
    }

    this.dialog.open(template, {
      id: '_chat_',
      ariaLabel:"Chat window",
      ariaModal: false,
      closeOnNavigation: true,
      delayFocusTrap: true,
      restoreFocus: true,
      hasBackdrop: false,
      panelClass:'pointyDialog',
      height: '600px',
      width: '420px',
      position: pos
    });
    const dlgFrame = document.querySelector('.pointyDialog') as HTMLElement;
    const dfStyle = dlgFrame.style;

    dfStyle.setProperty('--avatar-bg', `no-repeat center/cover url('${this.avatar=='spark'?this.assets.get('spark'):this.avatar}'), #eee`)
    dfStyle.setProperty('border-radius', '20px');
    dfStyle.setProperty('overflow', 'hidden');
    dfStyle.setProperty('box-shadow', 'var(--mdc-dialog-container-elevation-shadow');

    if ( this.xposition == 'before') {
      if (this.yposition == 'above') {
        dfStyle.setProperty('--point-top', `${origin.top}px`);
        dfStyle.setProperty('--point-left', `${origin.x + origin.width}px`);
        dfStyle.setProperty('--avatar-top', `${origin.top - height}px`);
        dfStyle.setProperty('--avatar-left', `${origin.x + origin.width - width}px`);
        dfStyle.setProperty('--point-bgcolor', 'white');
      } else {
        dfStyle.setProperty('--point-top', `${origin.top+origin.height}px`);
        dfStyle.setProperty('--point-left', `${origin.x + origin.width}px`);
        dfStyle.setProperty('--avatar-top', `${origin.top + origin.height}px`);
        dfStyle.setProperty('--avatar-left', `${origin.x + origin.width - width}px`);
        dfStyle.setProperty('--point-bgcolor', 'whitesmoke');
      }
    } else {
      if (this.yposition == 'above') {
        dfStyle.setProperty('--point-top', `${origin.top}px`);
        dfStyle.setProperty('--point-left', `${origin.x + origin.width }px`);
        dfStyle.setProperty('--avatar-top', `${origin.top - height}px`);
        dfStyle.setProperty('--avatar-left', `${origin.x }px`);
        dfStyle.setProperty('--point-bgcolor', 'white');
      } else {
        dfStyle.setProperty('--point-top', `${origin.top+origin.height}px`);
        dfStyle.setProperty('--point-left', `${origin.x + origin.width }px`);
        dfStyle.setProperty('--avatar-top', `${origin.top + origin.height}px`);
        dfStyle.setProperty('--avatar-left', `${origin.x}px`);
        dfStyle.setProperty('--point-bgcolor', 'whitesmoke');
      }
    }

    console.log(`DEBUG ${this.constructor.name} open chatbot`, window.location.hostname)
  }

  // Envia una questió "request" a l'agent Spark.
  sendQuestion(request:string):void {
    // Objecte auxiliar que conté la petició i l'observable de resposta (api call),
    // i a més cacheja la pròpia resposta un cop s'ha obtingut.
    const interaction:IInteraction = {
      request: request, 
      response$: this.api.query(this.projectUUID, this.espaiUUID, request, this.configUUID, this.token).pipe( 
        map((e)=>{ 
          if (!e.response||e.response=="Empty Response") throw(new Error("no tinc resposta"));
          let retval:IInteractionResponse = { response: e.response, query_id: e.query_id };
          if (Object.keys(e).includes('followUp')) retval = e;
          return retval; 
        }),
        catchError(err=>{
          interaction.response = `Ho sento, alguna cosa ha fallat (${err.message}). Prova-ho en un altre moment.`;
          interaction.followUp = this.availableQuestions;
          this.scrollChat();
          throw err;
        }),
        tap((resp:IInteractionResponse)=>{
          interaction.response=resp.response;
          interaction.query_id=resp.query_id;
          this.scrollChat();
        }),
      ) as Observable<IInteractionResponse>
    };

    this.messages.push(interaction);
    this.scrollChat();
  }

  // Assegura que el nou contingut al final del xat sempre queda a la vista 
  private scrollChat() {
    setTimeout(()=>{
      const chat = document.querySelector('.chat');
      chat?.scrollTo({top:chat?.scrollHeight, left:0, behavior:'smooth'});
    }, 1);
  }

  feedback(how:'correct'|'incorrect'|'non_evaluated', resp:IInteraction ) {
    this.api.feedback(this.projectUUID, this.espaiUUID, resp.query_id!, how, this.token).pipe(
      this.api.uify('Un momento...', 'Enviando valoración')
    ).subscribe({
      next:(nxt)=>resp.feedback=how
    })
  }

  // Neteja la sessió de chat
  async resetChat() {
    this.messages = [];
    await lastValueFrom(this.api.resetChat());
  }

}
