import { Component, OnDestroy, TemplateRef } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Subscription, combineLatest, debounceTime, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ApiService, IProjecte } from '../api.service';



@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss'
})
export class ProjectsComponent implements OnDestroy {

  private projects?:IProjecte[];
  cards$ = new BehaviorSubject<IProjecte[]|undefined>(this.projects);
  searchControl = new FormControl();

  // auxiliar responsive: mapeja media queries amb nº de columnes
  private columnMap = new Map([
    [Breakpoints.XSmall, 1],
    [Breakpoints.Small, 2],
    [Breakpoints.Medium, 2],
    [Breakpoints.Large, 3],
    [Breakpoints.XLarge, 4],
  ]);
  // nº de columnes a mostrar al grid
  currentCols = 4;

  // auxiliar per fer neteja de les subscripcions a l'OnDestroy
  private _subs: Subscription;

  constructor(
    private api: ApiService,
    private breakpoints: BreakpointObserver,
    private dialog: MatDialog
  ) {
    // implementa el filtrat
    this._subs = this.searchControl.valueChanges.pipe(
      debounceTime(350)
    ).subscribe({
      next: (search: string)=>{
        let retval: IProjecte[] = this.projects || [];
        if (search.length) {
          const regexp = new RegExp(`.*${search}.*`,'i'); // <- cerca insensible a majúscules
          retval = retval.filter(proj=>!!proj.name.match(regexp))
        }
        this.cards$.next(retval);
      }
    });

    // Escolta canvis d'amplada (responsive)
    const s = this.breakpoints.observe([
      Breakpoints.XLarge, Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall
    ]).subscribe({
      next:(result:any)=>{
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            // canvia el nº de columnes del grid
            this.currentCols = this.columnMap.get(query) ?? 4;
          }
        }
      }
    });
    // l'encadena a la neteja de subscripcions
    this._subs.add(s);

    // demana projectes a l'API 
    // (no cal neteja, la subscripció es completa)
    this.api.projects().pipe(
      this.api.uify("Cargando...", "Obteniendo lista de proyectos")
    ).subscribe({
      next:(projs)=>{
        this.projects = projs;
        this.projects = this.projects.filter((p) => p.enabled == true);
        this.cards$.next(this.projects);
      }
    })
  }

  createProjectDialog(t_ref:TemplateRef<any>): void {
    const dialogRef = this.dialog.open(t_ref);
    dialogRef.afterClosed().subscribe(result => {
      if (!!result) this.api.nouProject(result).pipe(
        this.api.uify('Creando proyecto...')
      ).subscribe({
        next:(r)=>{
          this.projects?.push(r);
          // reseteja el filtre
          this.searchControl.setValue('');
          this.cards$.next(this.projects);
        }
      });
      console.log(`DEBUG ${this.constructor.name}: The dialog was closed returning`, result);
    });
  }

  ngOnDestroy(): void {
      this._subs.unsubscribe();
  }

  userHasAdminRights(): boolean {
    return this.api.userHasAdminRights();
  }

}
