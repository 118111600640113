import { AfterViewInit, Component, ViewChild, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SessionDetailDataSource } from './session-detail.datasource';
import { ApiService, ISession, IUserQuery } from 'src/app/api.service';
import { environment } from 'src/environments/environment';
import { Subscription, combineLatest, startWith, debounceTime } from 'rxjs';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { ActivatedRoute } from '@angular/router';
import { MessageSenders } from 'src/app/agent-chat/agent-chat.component';

type fb_t = "NON_EVALUATED"|"CORRECT"|"INCORRECT"; 

@Component({
  selector: 'app-session-detail',
  templateUrl: './session-detail.component.html',
  styleUrl: './session-detail.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SessionDetailComponent implements AfterViewInit, OnDestroy {

    private _queries: IUserQuery[] = [];
    private _sub: Subscription;

    public numQueries:number = 0;
    public generalFeedback?:fb_t;

    MessageSenders = MessageSenders;

    @Input() uuid: string; // <- uuid del projecte (param url) 
    @Input() espai: string; // <- uuid de l'espai
    @Input() session: string;
    @Input() date: string; // <- millis (Date.getTime())
    @Input() set detail(_:any) {
        const aux = new Date(+this.date);
        const pad = (n:number)=>`${n}`.padStart(2,'0');
        const start_date = `${aux.getFullYear()}-${pad(aux.getMonth()+1)}-${pad(aux.getDate())}`;

        this.api.userQueries(this.uuid, this.espai, start_date, this.session).pipe(
            this.api.uify('Obtenint detall de la sessió...')
        ).subscribe({
            next:(nxt)=>{
                this._queries = this.dataSource.data = nxt;
                this.numQueries = nxt.length;
                this.debug(nxt, 'got queries:');
                const count = this._queries.reduce((a, b)=>{
                    a[b.feedback]+=1;
                    return a;
                }, { NON_EVALUATED:0, CORRECT:0, INCORRECT:0 });
                this.debug(count, 'generalFeedback:');
                Object.keys(count).forEach((fb)=>{
                    const feedb = fb as fb_t;
                    if (!this.generalFeedback) this.generalFeedback = fb as fb_t;
                    else if (count[feedb]>count[this.generalFeedback]) this.generalFeedback = feedb
                })
                this.fetchTrendingTopicsForSession();
            }
        });
    }

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatTable) table!: MatTable<IUserQuery>;
    dataSource = new SessionDetailDataSource();

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['query', 'data', 'feedback'];
    searchControl = new FormControl('');
    feedbackSelection = new FormControl('');
    @ViewChild("exporter") exporter! : MatTableExporterDirective;
    topics: { session: string, topic: string }[] = [];

    constructor(
        private api: ApiService,
        private route: ActivatedRoute,
    ) {
        // implementa el filtrat
        this._sub = combineLatest([
            this.searchControl.valueChanges.pipe(startWith(''), debounceTime(350)),
            this.feedbackSelection.valueChanges.pipe(startWith([]))
        ]).subscribe({
            next: ([search, fb])=>{
            let retval = this._queries || [];
            if (Array.isArray(fb) && fb.length) {
                retval = retval.filter(q=>!!fb.find(e=>q.feedback == e));
            }
            if (search?.length) {
                const regexp = new RegExp(`.*${search}.*`,'i'); // <- cerca insensible a majúscules
                retval = retval.filter(q=>!!q.query?.match(regexp))
            }
            this.dataSource.data = retval;
            }
        });
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.table.dataSource = this.dataSource;
    }

    ngOnDestroy(): void {
        this._sub.unsubscribe();
    }

    debug(sth:any, tag="") {
        if (environment.production) return;
        console.log(`DEBUG ${this.constructor.name} ${tag}`, sth);
    }

    exportTable() {
        this.exporter.exportTable('csv', {fileName: this.session});
        this.exporter.exportTable('xlsx', {fileName: this.session});
    }

    loadSessionTopics() {
        this.api.loadTrendingTopics(this.uuid, this.espai, this.session).subscribe({
            next: (nxt) => {
                this.fetchTrendingTopicsForSession();
            }
        });
    }

    fetchTrendingTopicsForSession() {
        this.api.getTrendingTopicsBySession(this.uuid, this.espai, this.session).subscribe({
            next: (nxt) => {
                this.topics = nxt.trending_topics;
            }
        });
    }

}