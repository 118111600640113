import { AfterViewInit, Component, Input, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { SourcesDataSource, SourcesItem } from './sources-datasource';
import { FormControl, FormGroup, FormRecord, Validators } from '@angular/forms';

import { Subscription, combineLatest, startWith, debounceTime, forkJoin, map, switchMap, EMPTY } from 'rxjs';
import { ApiService, ISpace, IProjecte, ITopic, ICategoryNode, ISearchConfiguration } from '../api.service';
import { environment } from 'src/environments/environment';

interface IEditForm {
  name: FormControl<string|null>;
  topic: FormControl<string|null>;
  metadata: FormRecord<FormControl<string|null>>
}

@Component({
  selector: 'app-sources',
  templateUrl: './sources.component.html',
  styleUrl: './sources.component.scss'
})
export class SourcesComponent implements AfterViewInit, OnDestroy {
  private _sources: SourcesItem[] = [];
  private _topics: ITopic[] = [];
  public categories: ICategoryNode[] = [];
  public searchConfigs: ISearchConfiguration[] = [];
  public topicNames: {[key:string]:string} = {}; // auxiliar
  public showExtra: boolean = true // auxiliar
  public selectedCategories = (s:SourcesItem)=>s.metadata_filters.find(kv=>kv.key=='category')?.value;
  private _detail!: ISpace;

  
  @Input() set projecte(proj:IProjecte) {
    if (!proj) return;
    // NOTA: A nivell projecte no hi ha Questions, etc...
    this.api.projectSources(proj.uuid).pipe(
      this.api.uify( proj.name, "Obteniendo fuentes...." )
    ).subscribe({
      next: (sources) => this._sources = this.sourcesDataSource.data = sources
    })
  }

  @Input() uuid?: string; // <- uuid del projecte (param de navegació)
  @Input() espai?: string; //<- uuid de l'espai (param de navegació)
  @Input() set detail(esp:ISpace) {
    if (!esp || !this.uuid) return;
    this._detail = esp;
    forkJoin({
      sources: this.api.espaiSources(this.uuid, esp.uuid),
      topics: this.api.espaiTopics(this.uuid, esp.uuid),
      categories: this.api.espaiCategories(this.uuid, esp.uuid),
      search_cfg: this.api.espaiSearchConfig(this.uuid, esp.uuid),
    }).pipe(
      this.api.uify( esp.name, "Obteniendo fuentes...." )
    ).subscribe({
      next: ({sources, topics, categories, search_cfg}) => {
        this._sources = this.sourcesDataSource.data = sources.filter(s=>!s.deleted);
        this._topics = topics;
        this._topics.forEach(t=>this.topicNames[t.key]=t.name);
        this.searchConfigs = search_cfg;
        this.categories = categories;

        // this.debug(this._sources, 'got sources:');
        // this.debug(this._topics, 'got topics:');
        // this.debug(this.categories, 'got tree of categories:');
        // this.debug(this.searchConfigs, 'got search config');
      }
    });
  }
  get detail() {
    return this._detail;
  }
  get topics() {
    return this._topics.map(t=>{ return {key: t.key, name:t.name } });
  }
  // Referència al template que construeix el diàleg de previsualització
  @ViewChild('preview', { static: true }) docPreview_t!: TemplateRef<{item: SourcesItem}>
  // Referència al template que construeix el diàleg d'edició
  @ViewChild('editsrc', { static: true }) editSrc_t!: TemplateRef<{item: SourcesItem, mdkeys: string[] }>
  // // Referència al template que construeix el diàleg d'edició
  // @ViewChild('delsrc_confirm', { static: true }) delSrc_t!: TemplateRef<{item: SourcesItem}>

  @ViewChild('sourcePaginator') sPaginator!: MatPaginator;
  @ViewChild('sourceSort') sSort!: MatSort;
  @ViewChild('sourceTable') sTable!: MatTable<SourcesItem>;
  sourcesDataSource = new SourcesDataSource();


  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedSourceColumns = [ 'type', 'name', 'tags', 'topic', 'lang', 'indexed', 'date', 'update', 'id'];

  sourcesSearchControl = new FormControl('');
  sourceTypeSelection = new FormControl('');
  sourceTagSelection = new FormControl('');
  sourceTopicSelection = new FormControl('');

  sourceEditForm:FormGroup<IEditForm> = new FormGroup({
    name: new FormControl('', Validators.required ),
    topic: new FormControl('', Validators.required),
    metadata: new FormRecord({
      type: new FormControl('', Validators.required)
    })
  })

  private _subs: Subscription;

  get sourceTypes() {
    return this._sources.map(s=>s.type).sort().filter((e,i,d)=>i<1||d[i-1]!=e)
  }

  get sourceTags() {
    return this._sources.filter(
      s=>!!s.metadata_filters
    ).map(
      s=>s.metadata_filters.map(f=>f.value.split(',')).reduce((a,b)=>a.concat(b),[])
    ).reduce(
      (a,b)=>a.concat(b),[]
    ).sort().filter((e,i,d)=>i<1||d[i-1]!=e)
  }

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
  ) {
    // implementa el filtrat
    this._subs = combineLatest([
      this.sourcesSearchControl.valueChanges.pipe(startWith(''), debounceTime(350)),
      this.sourceTypeSelection.valueChanges.pipe(startWith([])),
      this.sourceTagSelection.valueChanges.pipe(startWith([])),
      this.sourceTopicSelection.valueChanges.pipe(startWith([]))
    ]).subscribe({
      next: ([search, types, tags, topics])=>{

        let retval: SourcesItem[] = this._sources;
        if (Array.isArray(types) && types.length) {
          retval = retval.filter(source=>!!types.find(type=>source.type==type));
        }
        if (Array.isArray(tags) && tags.length) {
          // cerca els tags dins els 'value:' de metadata_filters com a valors separats per comes:
          retval = retval.filter(source=>!!tags.find(
              tag=>Array.isArray(source.metadata_filters) && source.metadata_filters.map(
                f=>f.value.split(',')
              ).reduce(
                (a,b)=>a.concat(b), []
              ).includes(tag)
            )
          );
        }
        if (Array.isArray(topics) && topics.length) {
          retval = retval.filter(s=>!!topics.find(tkey=>s.topic?.key==tkey));
        }
        if (search?.length) {
          const regexp = new RegExp(`.*${search}.*`,'i'); // <- cerca insensible a majúscules
          retval = retval.filter(source=>!!source.name?.match(regexp))
        }

        this.debug(retval, 'aplicant filtres');

        this.sourcesDataSource.data = retval;
      }
    });
  }

  ngAfterViewInit(): void {
    this.connectCurrentDataSource({selectedIndex:0});
  }

  connectCurrentDataSource({selectedIndex}:{selectedIndex: number}) {
    // connecta els datasources a les taules associades amb els
    // canvis del stepper perquè tinguin valor les variables del template (@ViewChild)
    switch(selectedIndex) {
      case 1:
        // this.questionsDataSource.sort = this.qSort;
        // this.questionsDataSource.paginator = this.qPaginator;
        // this.qTable.dataSource = this.questionsDataSource;
        break;
      default: 
        this.sourcesDataSource.sort = this.sSort;
        this.sourcesDataSource.paginator = this.sPaginator;
        this.sTable.dataSource = this.sourcesDataSource;
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  previewDoc(source: SourcesItem) {
    this.dialog.open(this.docPreview_t, { 
      data: { item: source },
    });
  }

  editSource(source: SourcesItem) {
    // esbrina la forma actual de les metadades al formulari
    const mdCtl = this.sourceEditForm.controls.metadata;
    mdCtl.get('type')?.reset();
    const mdKeys = Object.keys(mdCtl.controls);
    const rem = mdKeys.filter(k=>k!='type'); // <- només type habilitat per defecte;
    rem.forEach(k=>mdCtl.removeControl(k)); // <- elimina els controls innecessaries
    const currentMD = source.metadata_filters;
    currentMD.forEach(md=>{ // <- afegeix els controls que calgui
      if (md.key!='type') {
        mdCtl.addControl(md.key, new FormControl(md.value, Validators.required))
      } else {
        mdCtl.get('type')?.setValue(md.value)
      }
    });
    // reflecteix els valors actuals al formulari d'edició:
    this.sourceEditForm.reset({ name: source.name, topic:source.topic?.key, metadata:mdCtl.value });
    
    const dref = this.dialog.open(this.editSrc_t, {
      width: '460px',
      height: '575px',
      data: { 
        item: source, 
        mdkeys: Object.keys(mdCtl.controls).filter(k=>k!='category'), // <- Evita duplicar les categories (component dedicat).
        selection: this.selectedCategories(source),
      }
    });
    dref.afterClosed().subscribe((data:any)=>{
      this.debug(data, 'editSource returns:');
      if (!data) return;
      data.metadata_filters = Object.keys(data.metadata).map(k=>{return { key: k, value: data.metadata[k] }; });
      delete data.metadata;
      this.api.modifySource(this.uuid||'', this.espai||'', source.uuid||'', data).pipe(
        this.api.uify('Editar fuente', 'Enviando cambios...'),
      ).subscribe(nxt=>{
        this.debug(nxt, 'api returns:');
        source.name = data.name;
        source.metadata_filters = data.metadata_filters
        this.debug(source.metadata_filters, 'noves metadades:')
        source.topic = this._topics.find(t=>t.key == data.topic);
      })
    })
  }

  delSource(source: SourcesItem) {
    this.api.confirmDialog(
      this.api.deleteSource(this.uuid||'', this.espai||'', source.uuid||'').pipe(
        this.api.uify('Eliminar fuente', 'Enviando cambios...'),
      ),
      `¿Quiere eliminar la fuente "${source.name}"?`
    ).subscribe(nxt=>{
      this.debug(nxt, 'delSource responds:')
      this.sourcesDataSource.data = this.sourcesDataSource.data.filter(s=>s.uuid!=source.uuid)
    })
  }

  // afegeix un nou control a les metadades del sourceEditForm
  addKey(name: string) {
    this.sourceEditForm.controls.metadata.addControl(name, new FormControl('', Validators.required));
  }

  setCategorySelection(names:string[]) {
    const md_record = this.sourceEditForm.controls.metadata;
    if (!md_record.contains('category')) {
      md_record.addControl('category', new FormControl(''));
    }
    md_record.get('category')?.setValue(names.join(','))
  }

  debug(sth:any, tag="") {
    if (environment.production) return; // <- No mostrar a producció
    console.log(`DEBUG ${this.constructor.name}, ${tag}`, sth);
  }

  userHasAdminRights(): boolean {
    return this.api.userHasAdminRights();
  }
}
