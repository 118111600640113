<div class="chat">
    <div class="messages" #chat>
        @for (message of conversation; track $index) {
            <div class="message" [ngStyle]="{'justify-content': isMessageUserView(message) ? 'start' : 'end'}">
                <div class="message-content">
                    <div class="avatar">
                        <img src="assets/star.svg" class="avatar"/>
                    </div>
                    <div class="text">
                        <markdown [data]="message.message"></markdown>
                    </div>
                </div>
            </div>
        }
        @if (loadingMessage) {
            <div class="loading">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        }
    </div>
    <div class="input">
        <input [(ngModel)]="inputMessage" [attr.disabled]="loadingMessage ? true : null" placeholder="Introdueix la consulta aquí" (keyup.enter)="sendMessage()">
        <button mat-fab color="primary" aria-label="Envia la petició" style="box-shadow: none;" (click)="sendMessage()">
            <mat-icon>send</mat-icon>
          </button>
    </div>
</div>