import { Component, Input,OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Subscription, combineLatest, debounceTime, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService, IProjecte, ISpace, /*space_t*/ } from 'src/app/api.service';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrl: './project-detail.component.scss'
})
export class ProjectDetailComponent implements OnInit, OnDestroy {

  @Input() uuid?:string;
  @Input() detail?:IProjecte

  private espais?:ISpace[];
  cards$ = new BehaviorSubject<ISpace[]|undefined>(this.espais);
  searchControl = new FormControl();
  // filterSelection = new FormControl();
  // // display names pels filtres
  // filterNames: Record<space_t, string> = {
  //   space_1: "Tipus 1",
  //   space_2: "Tipus 2",
  //   space_3: "Tipus 3",
  // }
  // // obté el llistat de filtres disponible
  // get spaceTypeFilters() {
  //   return <unknown>Object.keys(this.filterNames) as space_t[];
  // }

  // auxiliar responsive: mapeja media queries amb nº de columnes
  private columnMap = new Map([
    [Breakpoints.XSmall, 1],
    [Breakpoints.Small, 2],
    [Breakpoints.Medium, 2],
    [Breakpoints.Large, 3],
    [Breakpoints.XLarge, 4],
  ]);
  // nº de columnes a mostrar al grid
  currentCols = 4;

  // auxiliar per fer neteja de les subscripcions a l'OnDestroy
  private _subs: Subscription;

  constructor(
    private api: ApiService,
    private breakpoints: BreakpointObserver,
    private dialog: MatDialog
  ) {
    // implementa el filtrat
    // this._subs = combineLatest([
    //   this.searchControl.valueChanges.pipe(startWith(''), debounceTime(350)),
    //   this.filterSelection.valueChanges.pipe(startWith([]))
    // ]).subscribe({
    this._subs = this.searchControl.valueChanges.pipe(
        debounceTime(350)
    ).subscribe({
      next: (search: string)=>{ //([search, filters]: [string, space_t[]])=>{
        let retval: ISpace[] = this.espais || [];
        // if (filters.length) {
        //   retval = retval.filter(proj=>!!filters.find(type=>proj.tipus==type));
        // }
        if (search.length) {
          const regexp = new RegExp(`.*${search}.*`,'i'); // <- cerca insensible a majúscules
          retval = retval.filter(proj=>!!proj.name.match(regexp))
        }
        this.cards$.next(retval);
      }
    });

    // Escolta canvis d'amplada (responsive)
    const s = this.breakpoints.observe([
      Breakpoints.XLarge, Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall
    ]).subscribe({
      next:(result:any)=>{
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            // canvia el nº de columnes del grid
            this.currentCols = this.columnMap.get(query) ?? 4;
          }
        }
      }
    });
    // l'encadena a la neteja de subscripcions
    this._subs.add(s);
  }

  ngOnInit(): void {
    // demana espais del projecte a l'API 
    // (no cal neteja, la subscripció es completa)
    this.api.espais(`${this.uuid}`).pipe(
      this.api.uify("Cargando...", "Obteniendo lista de espacios")
    ).subscribe({
      next:(spaces)=>{
        this.espais = spaces;
        this.espais = this.espais.filter((p) => p.enabled == true);
        this.cards$.next(this.espais);
      }
    })
  }

  createSpaceDialog(t_ref:TemplateRef<any>): void {
    const dialogRef = this.dialog.open(t_ref);
    dialogRef.afterClosed().subscribe(result => {
      const uuid = this.detail?.uuid
      if (!!uuid && !!result) this.api.nouEspai(uuid, result).pipe(
        this.api.uify('Creando espacio...')
      ).subscribe({
        next:(r)=>{
          this.espais?.push(r);
          // reset als filtres
          this.searchControl.setValue('');
          this.cards$.next(this.espais);
        }
      });
      console.log(`DEBUG ${this.constructor.name}: The dialog was closed returning`, result);
    });
  }


  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  userHasAdminRights(): boolean {
    return this.api.userHasAdminRights();
  }
}

